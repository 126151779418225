import React, { useRef, useState, useEffect } from "react";
import { auth } from "../firebase";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";

import { AuthenticateWithAuthPopup } from "../services/cyauthHelper";
import { verifyCode } from "../functions/verify";
import { postWithCredentials} from "../contexts/AuthContext";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function TwoFAPopUp(props) {
  const codeRef = useRef("");
  const [has2FA , setHas2FA ] = useState(false);
  const [authType, setAuthType] = useState("none");

  // get the user's info (which authType)
  useEffect(() => {
    postWithCredentials(SERVER_URL + "have2fa", {
      user_id: auth.currentUser.uid,
    })
      .then((res) => {
        setHas2FA(res.data.haveSecret);
        setAuthType(res.data.authType);
        console.log("authType:", authType, " has2FA:", has2FA);
      });
  }, []);

  function generateContent() {
    if (!has2FA) {
      return (
        <p>
          { "You need to register for 2-Factor Authentication (2FA) before you can " } 
          {props.task} 
          {". Click " }
          <Link to="/set-google-auth">{"here"}</Link>
          { " to register for 2FA." }
        </p>
      );
    } else if (authType === "cyauth") {
      return (
        <Row className="justify-content-center">
          <Col xs={props.long ? 2 : 3}
            className="mb-2 d-flex justify-content-end">
            <label for="cyauth-button">Authenticate</label>
          </Col>
          <Col>
            <Button
              className="w-100 mx-0"
              id="cyauth-button"
              onClick={async () => {
                try {
                  await AuthenticateWithAuthPopup(auth.currentUser.uid);
                  props.tried2FA(true);
                  props.verified(true);
                } catch (e) {
                  props.tried2FA(true);
                  props.verified(false);
                  throw (e);
                }
              }}>
              Begin
            </Button>
          </Col>
        </Row>
      );
    } else if (authType === "google") {
      return (
        <Row>
          <Col lg={2} md={3} sm={12} xs={12}
            className="mb-2 mb-lg-0 ps-3 d-flex justify-content-end">
            2FA Code
          </Col>
          <Col lg={7} md={7} sm={11} xs={9} className="pe-4">
            <input
              name="uses"
              className="form-control"
              type="text"
              ref={codeRef}
              required
            />
          </Col>
          <Col xs={"auto"}
          className="px-0 mx-0">
            <Button
              onClick={async () => {
                const verified = await verifyCode(codeRef.current.value, auth.currentUser.uid);
                props.tried2FA(true);
                props.verified(verified);
              }}>
              Verify Code
            </Button>
          </Col>
        </Row>
      );
    }
  }

  return (
    <div className="px-0">
      {generateContent()}
    </div>
  )
}