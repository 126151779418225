import CryptoJS from "crypto-js";

/**
 * Number of bytes to read off of the beginning of the files for generating proofs and file identifiers
 */
const BYTES_TO_READ = 32;

/**
 * Size of the initial value vector in bytes
 */
const IV_BYTES = 16;

const SLICE_SIZE = 10 * 1024 * 1024;

/**
 * Creates file identifier, random key, AES encryption IV, and proof.
 * @param {File} file - File to encrypt
 * @returns {Promise} - Resolved with an array containing relevant file data when encryption is completed
 */
export function oldEncrypt(file) {
  let reader = new FileReader();

  function onData(res, rej, reader) {
    // Get the raw file data as bytes
    let typedArrayInput = new Uint8Array(reader.result);
    let wordArrayInput = bytesToWordArray(typedArrayInput);
    // Get init data (first 32 bytes) and hash it
    let initHashed = hashInitial(wordArrayInput);

    // Generate a random key and inital value vector
    let randomKey = new Uint8Array(BYTES_TO_READ);
    let originalKey = bytesToWordArray(crypto.getRandomValues(randomKey));

    let iv = new Uint8Array(IV_BYTES);
    iv = bytesToWordArray(crypto.getRandomValues(iv));

    // Encrypt the file
    let encryptedFileData = CryptoJS.AES.encrypt(wordArrayInput, originalKey, {
      mode: CryptoJS.mode.CFB,
      iv: iv,
    });

    // Hash the first 32 bytes and perform an XOR with the random key
    let xHashed = hashInitial(encryptedFileData.ciphertext);
    let x2Hashed = CryptoJS.SHA256(xHashed);
    let xOrKey = performXOR(xHashed, originalKey);
    // Generate 6 digit number for one-time-password
    let otp = generateCode(12).toString();
    xOrKey = performXOR(xOrKey, CryptoJS.SHA256("" + otp));
    let data = [x2Hashed, xOrKey, initHashed, iv, otp, encryptedFileData];
    res(data);
  }

  return new Promise((res, rej) => {
    reader.onload = () => onData(res, rej, reader);
    reader.readAsArrayBuffer(file);
  });
}

/**
 * Creates file identifier, random key, AES encryption IV, and proof, using progressive hashing to save on memory usage.
 * @param {File} file - File to encrypt
 * @param {FileSystemFileHandle} handle - handle for writing encrypted file
 * @param {Function} setDP - function handle to update download progress bar
 * @returns {Promise} - Resolved with an array containing relevant file data when encryption is completed
 */
export async function encrypt(file, handle, setDP) {
  async function onData(res, rej) {
    let start = 0;
    // Generate a random key and inital value vector
    let randomKey = new Uint8Array(BYTES_TO_READ);
    let originalKey = bytesToWordArray(crypto.getRandomValues(randomKey));
    let iv = new Uint8Array(IV_BYTES);
    iv = bytesToWordArray(crypto.getRandomValues(iv));

    let aesEnc = CryptoJS.algo.AES.createEncryptor(originalKey, {
      mode: CryptoJS.mode.CFB,
      iv: iv,
    });

    //let encryptedFileData = []

    // Get the raw file data as bytes

    let initWA;
    let initEnc;

    const filestream = await handle.createWritable();
    const writer = await filestream.getWriter();

    while (start < file.size) {
      let slice = await readSlice(file, start, SLICE_SIZE);
      let wordArrayInput = CryptoJS.lib.WordArray.create(slice);
      let encryptedFileData = aesEnc.process(wordArrayInput);
      downloadFile(encryptedFileData, "placeholder", writer);
      if (start === 0) {
        initWA = wordArrayInput;
        initEnc = encryptedFileData;
      }
      start += SLICE_SIZE;
      setDP({ downloadProgress: (start / file.size) * 100 });
      //console.log(wordArrayInput, encryptedFileData[encryptedFileData.length - 1])
    }

    downloadFile(aesEnc.finalize(), "placeholder", writer);
    writer.close();

    // Get init data (first 32 bytes) and hash it
    let initHashed = hashInitial(initWA);

    // Hash the first 32 bytes and perform an XOR with the random key
    let xHashed = hashInitial(initEnc);
    let x2Hashed = CryptoJS.SHA256(xHashed);
    let xOrKey = performXOR(xHashed, originalKey);

    let otp = generateCode(12).toString();
    xOrKey = performXOR(xOrKey, CryptoJS.SHA256("" + otp));
    let data = [x2Hashed, xOrKey, initHashed, iv, otp, initEnc];
    res(data);
  }

  return new Promise((res, rej) => {
    onData(res, rej);
  });
}

/**
 * Generates alphanumeric code with length characters
 * @param {int} length - length of code
 * @returns {string} - randomized code
 */
function generateCode(length) {
  let chars = "-+()1234567890";
  let code = "";
  for (let i = 0; i < length; i++) {
    code += chars[Math.floor(Math.random() * chars.length)];
  }
  return code;
}

/**
 * Gets a unique file identifier from the encrypted file
 * @param {WordArray} encryptedDataWA - Encrypted file data WordArray
 * @returns {WordArray} - Returns first 32 bytes of the encrypted file, hashed with SHA256
 */
function hashInitial(encryptedDataWA) {
  let encryptedWords = encryptedDataWA.words.slice(0, BYTES_TO_READ >>> 2);
  while (encryptedWords.length < BYTES_TO_READ >>> 2) {
    encryptedWords.push(0);
  }
  let x = CryptoJS.lib.WordArray.create(encryptedWords, BYTES_TO_READ);
  let xHashed = CryptoJS.SHA256(x);
  return xHashed;
}

/**
 * Returns a unique file identifier using information from the encrypted file
 * @param {File} file - File whose identifier to get
 * @returns {Promise} - Resolved with identifier when the process is completed
 */
export async function getFileIdentifier(file) {
  async function onData(res, rej) {
    try {
      let slice = await readSlice(file, 0, SLICE_SIZE);
      let encryptedData = CryptoJS.lib.WordArray.create(slice);
      let xHashed = hashInitial(encryptedData);
      let x2Hashed = CryptoJS.SHA256(xHashed);
      res(x2Hashed);
    } catch {
      rej("Failed to get file identifier.");
    }
  }

  return new Promise((res, rej) => {
    onData(res, rej);
  });
}
/**
 * DEPRECATED -- TO MODIFY DECRYPTION DO IT IN THE electron-react-app-decrypt (remove this when possible)
 * Decrypts the file and downloads the data
 * @param {File} file - File to decrypt
 * @param {WordArray} encryptedKey - Randomly generated 32 bit key XORed with the file identifier
 * @param {WordArray} initialValue - Randomly generated 16 byte number from the database
 * @returns
 */
export function decrypt(file, encryptedKey, initialValue, code) {
  let reader = new FileReader();

  function onData(res, rej, reader, encryptedKey, initialValue, code) {
    let encryptedData;
    let xHashed;
    let xOrKey = JSON.parse(encryptedKey);
    let iv = JSON.parse(initialValue);
    let otp = JSON.parse(code);
    try {
      let binaryData = new Uint8Array(reader.result);
      encryptedData = bytesToWordArray(binaryData);
      // Get the file identifier, encrypted key, and initial value vector
      xHashed = hashInitial(encryptedData);
    } catch {
      rej("Failed to get file identifier.");
    }
    // XOR the key with the file identifier to get the original key back
    let actualKey = performXOR(xHashed, xOrKey);
    actualKey = performXOR(actualKey, CryptoJS.SHA256("" + otp));

    let encryptedObject = CryptoJS.lib.CipherParams.create({
      ciphertext: encryptedData,
    });
    // Decrypt the file
    let decryptedData = CryptoJS.AES.decrypt(encryptedObject, actualKey, {
      iv: iv,
      mode: CryptoJS.mode.CFB,
    });

    let initHashedCheck = hashInitial(decryptedData);
    let x2Hashed = CryptoJS.SHA256(xHashed);

    res({
      init: x2Hashed,
      proof: initHashedCheck,
      data: decryptedData,
    });
  }

  return new Promise((res, rej) => {
    reader.onload = () =>
      onData(res, rej, reader, encryptedKey, initialValue, code);
    reader.readAsArrayBuffer(file);
  });
}

/**
 * Converts an array of 8 bit data into a CryptoJS WordArray
 * @param {Array} bytes - Any type of array containing 8 bit numerical data
 * @returns {WordArray} - CryptoJS word array, consisting of 32-bit words
 */
function bytesToWordArray(bytes) {
  var words = [];
  for (var i = 0; i < bytes.length; ++i) {
    var j = 24 - (i % 4) * 8;
    words[i >>> 2] |= bytes[i] << j;
  }
  return CryptoJS.lib.WordArray.create(words, bytes.length);
}

/**
 * Converts a CryptoJS WordArray into an 8 bit array
 * @param {WordArray} wa - 8 bit data encoded into a CryptoJS word array (32 bit words)
 * @returns {Uint8Array} - 8 bit array containing the hex data
 */
function wordArrayToBytes(wa) {
  let bytes = [];
  for (var i = 0; i < wa.sigBytes; ++i) {
    var j = 24 - (i % 4) * 8;
    bytes.push((wa.words[i >>> 2] >>> j) & 0xff);
  }
  return new Uint8Array(bytes);
}

/**
 * XORs two WordArrays, clamping the length to equal the smaller WordArray.
 * @param {WordArray} a - First WordArray to XOR
 * @param {WordArray} b - Second WordArray to XOR
 * @returns {WordArray} - XORed WordArray
 */
function performXOR(a, b) {
  let XORSize = Math.min(a.words.length, b.words.length);
  let c = [];
  for (let i = 0; i < XORSize; ++i) {
    c.push(a.words[i] ^ b.words[i]);
  }
  return CryptoJS.lib.WordArray.create(c, Math.min(a.sigBytes, b.sigBytes));
}

/**
 * Saves given data to file. Converts it to raw binary if necessary.
 * @param {WordArray} data - WordArray containing data to be downloaded
 * @param {string} filename - Desired name for save file (currently unused)
 * @param {WritableStreamDefaultWriter} writer - writer used to write encrypted data to file
 */
export async function downloadFile(data, filename, writer) {
  if (!("showSaveFilePicker" in window)) {
    document.body.textContent =
      "Unsupported browser... Please try with latest Chrome browser.";
  }
  writer.write(wordArrayToBytes(data));
}

/**
 * Saves given data to file. Converts it to raw binary if necessary.
 * @param {WordArray} data - WordArray containing data to be downloaded
 * @param {string} filename - Desired name for save file
 */
export function oldDownloadFile(data, filename) {
  let binData = wordArrayToBytes(data);

  // Create a binary blob
  let blob = new Blob([binData], { type: "application/octet-stream" });

  // Give the blob a URL
  let url = window.URL.createObjectURL(blob);

  // Create a click element, attach the blob, and download the file through a click
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = url;
  a.download = filename;
  a.click();

  // Remove the blob's URL
  window.URL.revokeObjectURL(url);
}

export function oldDecrypt(file, encryptedKey, initialValue, code) {
  const FILENAME = file.name;
  let reader = new FileReader();

  function onData(res, rej, reader, encryptedKey, initialValue) {
    let encryptedData;
    let xHashed;
    let xOrKey = JSON.parse(encryptedKey);
    let iv = JSON.parse(initialValue);
    let otp = code;
    try {
      let binaryData = new Uint8Array(reader.result);
      encryptedData = bytesToWordArray(binaryData);
      // Get the file identifier, encrypted key, and initial value vector
      xHashed = hashInitial(encryptedData);
    } catch {
      rej("Failed to get file identifier.");
    }

    // XOR the key with the file identifier to get the original key back
    let actualKey = performXOR(xHashed, xOrKey);
    actualKey = performXOR(actualKey, CryptoJS.SHA256("" + otp));

    let encryptedObject = CryptoJS.lib.CipherParams.create({
      ciphertext: encryptedData,
    });
    // Decrypt the file
    let decryptedData = CryptoJS.AES.decrypt(encryptedObject, actualKey, {
      iv: iv,
      mode: CryptoJS.mode.CFB,
    });

    oldDownloadFile(
      decryptedData,
      FILENAME.slice(0, FILENAME.length - 3) + ".zip"
    );

    let initHashedCheck = hashInitial(decryptedData);
    let x2Hashed = CryptoJS.SHA256(xHashed);

    res({
      init: x2Hashed,
      proof: initHashedCheck,
    });
  }

  return new Promise((res, rej) => {
    reader.onload = () => onData(res, rej, reader, encryptedKey, initialValue);
    reader.readAsArrayBuffer(file);
  });
}

/**
 * Reads slice of user's uploaded file.
 * @param {File} file - file containing data to be read
 * @param {int} start - starting position of slice to be read
 * @param {int} size - size of slice to be read
 */
async function readSlice(file, start, size) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    const slice = file.slice(start, start + size);

    fileReader.onload = () => resolve(new Uint8Array(fileReader.result));
    fileReader.onerror = reject;
    fileReader.readAsArrayBuffer(slice);
  });
}
