import {
  DECRYPTION_NEEDS_VERIFICATION,
  DECRYPTION_SUCCESS,
  DECRYPTION_NOT_FOUND,
  DECRYPTION_BAD_PROOF,
  ENCRYPTION_SUCCESS,
  SERVER_DECRYPT_FAILURE,
  SERVER_DECRYPT_NOT_FOUND,
  SERVER_DECRYPT_BAD_PROOF,
  SERVER_ENCRYPTION_SUCCESS,
  SERVER_URL,
  SERVER_2FA_NEED_VERIFICATION,
  SERVER_USER_FOUND,
  SERVER_USER_NOT_FOUND,
  SERVER_SUBSCRIPTION_FOUND,
  SERVER_SUBSCRIPTION_NOT_FOUND,
  USER_DATA_SUBSCRIPTION_FOUND,
  USER_DATA_SUBSCRIPTION_NOT_FOUND,
  USER_DATA_FOUND,
  USER_DATA_NOT_FOUND,
  SERVER_GETUSES_SUCCESS,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_FAILURE,
} from "../assets/js/constants";
import axios from "axios";
import { handleConnectionError } from "./notifications";
import { postWithCredentials } from "../contexts/AuthContext"

/**
 * Communicates with the server to insert encryption data about the current file into the Mongo database
 * @param {Object} data - An object containing encryption information about the file, including the key, number of uses, and expiry date
 * @returns {Object} - Returns an object with a message key indicating a successful database insertion. Throws an error otherwise.
 */
export function sendEncryptionData(data) {
  return postWithCredentials(
      SERVER_URL + "encrypt",
      {},
      {
        params: {
          init: data.init,
          key: data.key,
          proof: data.proof,
          iv: data.iv,
          otp: data.otp,
          time: data.time,
          email: data.email,
          recipientContact: data.recipientContact,
          filename: data.filename,
          message: data.message,
        },
      }
    )
    .then((res) => {
      if (res.data.status === SERVER_ENCRYPTION_SUCCESS)
        return { message: ENCRYPTION_SUCCESS };
    })
    .catch((err) => handleConnectionError(err));
}

/**
 * Communicates with Firebase Cloud Function server to get a reference to the file on the database,and initiates a 2FA request if needed
 * @param {string} id - The first 32 bytes of the encrypted file, hashed with SHA256, converted to WordArray. Unique identifier for the file on the database.
 * @param {string} number - The phone number with which to initiate a 2FA request if it is required
 * @returns {Object} - Information about the file stored on the database. Specifically its key and initial value for AES encryption.
 */
export function getDecryptionData(id) {
  return axios.post(
      SERVER_URL + "decrypt",
      {},
      {
        params: {
          init: id,
        },
      }
    )
    .then(res => {
        if (res.data.status === SERVER_DECRYPT_FAILURE && res.data.reason === SERVER_DECRYPT_NOT_FOUND) {
            return {message: DECRYPTION_NOT_FOUND};
        } else if (res.data.status === SERVER_2FA_NEED_VERIFICATION) {
            // console.log("server count value" + res.data )
            for (const key of Object.keys(res.data)) {
                // console.log(key)
              }
            return {
                iv: res.data.iv,
                key: res.data.key,
                number: res.data.number,
                count: res.data.count,
                otp: res.data.otp,
                message: DECRYPTION_NEEDS_VERIFICATION
            };
        }
    }).catch(err => handleConnectionError(err));
}

/**
 * Sends a message to the server indicating the file was decrypted properly. Server sends email notification and checks if decryption was done correctly.
 * @param {string} init - The first 32 bytes of the encrypted file, hashed with SHA256, converted to WordArray. Unique identifier for the file on the database.
 * @param {string} proof - The first 32 bytes of the decrypted file, hashed with SHA256, converted to WordArray. Proves that the file was decrypted properly
 * @param {string} number - Decrypter's phone number, to send to encrypter within the email notification
 * @returns {Object} - Returns status of decryption and reason for failure if there is one. Throws an error if connection to server fails.
 */
export function sendDecryptSuccess(init, proof, number) {
  return axios.post(
      SERVER_URL + "decryptsuccess",
      {},
      {
        params: {
          init: init,
          proof: proof,
          decryptor: number,
        },
      }
    )
    .then((res) => {
      if (res.data.status === SERVER_DECRYPT_FAILURE) {
        if (res.data.reason === SERVER_DECRYPT_NOT_FOUND)
          return { message: DECRYPTION_NOT_FOUND };
        else if (res.data.reason === SERVER_DECRYPT_BAD_PROOF)
          return { message: DECRYPTION_BAD_PROOF };
      } else if (res.data.status === SERVER_ENCRYPTION_SUCCESS) {
        return { message: DECRYPTION_SUCCESS };
      }
    })
    .catch((err) => handleConnectionError(err));
}

/**
 * Send Reshare message to sender and recipient.
 * @param {string} init_ - Init hex: First 32 bytes of the encrypted file, hashed
 */
export function sendReshareMessage(init) {
  return postWithCredentials(
      SERVER_URL + "sendReshareMessage",
      {},
      {
        params: {
          init: init,
        },
      }
    )
    .then((res) => {
      return { message: NOTIFICATION_SUCCESS };
    })
    .catch((res) => {
      return { message: NOTIFICATION_FAILURE };
    });
}

/**
 * Finds the given user in the database
 *
 * @param {string} id - ID of user in the database
 * @returns {Object} - Message indicating whether user was found
 */
export function findUser(id) {
  return postWithCredentials(
      SERVER_URL + "getUser",
      {},
      {
        params: {
          id: id,
        },
      }
    )
    .then((res) => {
      if (res.data.status === SERVER_USER_FOUND) {
        return { message: USER_DATA_FOUND, user: res.data.userData };
      } else if (res.data.status === SERVER_USER_NOT_FOUND) {
        return { message: USER_DATA_NOT_FOUND };
      }
    })
    .catch((err) => handleConnectionError(err));
}

/**
 * Contacts payment service to see if the provided subscription is still valid
 * @param {string} id - ID of the subscription
 * @returns {Object} - Message indicating if subscription was found, and gives date of expiry.
 */
export function checkSubscriptionValidity(id) {
  return postWithCredentials(
      SERVER_URL + "subscriptionCheck",
      {},
      {
        params: {
          id: id,
        },
      }
    )
    .then((res) => {
      if (res.data.status === SERVER_SUBSCRIPTION_FOUND) {
        return {
          message: USER_DATA_SUBSCRIPTION_FOUND,
          subscription: res.data.subscription,
          stillValid: res.data.stillValid,
        };
      } else if (res.data.status === SERVER_SUBSCRIPTION_NOT_FOUND) {
        return { message: USER_DATA_SUBSCRIPTION_NOT_FOUND };
      }
    })
    .catch((err) => handleConnectionError(err));
}

/**
 * Finds the number of uses left for the given file
 * @param {string} id - The first 32 bytes of the encrypted file, hashed with SHA256, converted to WordArray. Unique identifier for the file on the database.
 * @returns {Object} - Message indication if the number of uses was found, and, if it was found, the number of uses.
 */
export function getUses(id) {
  return axios.post(
      SERVER_URL + "getUses",
      {},
      {
        params: {
          init: id,
        },
      }
    )
    .then(res => {
        if (res.data.status === SERVER_DECRYPT_FAILURE && res.data.reason === SERVER_DECRYPT_NOT_FOUND) {
            // console.log("Get Uses Failure -- Not Found")
            return {message: DECRYPTION_NOT_FOUND};
        } else if (res.data.status === SERVER_GETUSES_SUCCESS) {
            return {
                uses: res.data.uses,
                message: SERVER_GETUSES_SUCCESS
            };
        }
    }).catch(err => handleConnectionError(err));
}

export function setTime(id, time) {
  return axios.post(
    SERVER_URL + "setSMS",
    {},
    {
      params: {
        init: id,
        sendTime: time,
      },
    }
  );
}

