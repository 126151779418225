import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ComponentCard from "./new/ComponentCard";
import { auth } from "../firebase";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody
} from "mdb-react-ui-kit";
import { Card, Row } from "react-bootstrap";

export default function MaintenanceDashboard() {
  const [hasAccess, setHasAccess] = useState(false);
  useEffect(() => {
    const userEmail = auth.currentUser.email;
    if (userEmail !== "maintenance@cynorix.com") return;
    setHasAccess(true);
  }, []);

  let content = (
    <>
      {hasAccess && (
        <Card.Body>
          <Row>
            <Link
              to="/edit-service-class"
              className="btn btn-secondary w-70 mt-3"
            >
              Edit Subscription Tiers
            </Link>
          </Row>
        </Card.Body>
      )}
      {!hasAccess && (
        <>
          <MDBContainer fluid>
            <MDBRow className="d-flex justify-content-center align-items-center h-100 mt-5">
              <MDBCol col="12">
                <MDBCard
                  className="bg-dark text-white my-5 mx-auto"
                  style={{ borderRadius: "1rem", maxWidth: "500px" }}
                >
                  <MDBCardBody className="p-5 d-flex flex-column align-items-center mx-auto w-100">
                    <h2 className="text-center mb-4">Maintenance Dashboard</h2>
                    <hr
                      style={{
                        height: "3px",
                        backgroundColor: "white",
                        color: "white",
                        width: "96%",
                      }}
                    />
                    <Link
                      to="/edit-service-class"
                      className="btn btn-secondary w-100 mt-3"
                    >
                      Edit Subscription Tiers
                    </Link>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </>
      )}
      {!hasAccess && (
        <MDBContainer fluid>
          <MDBRow className="d-flex justify-content-center align-items-center h-100 mt-5">
            <MDBCol col="12">
              <MDBCard
                className="bg-dark text-white my-5 mx-auto"
                style={{ borderRadius: "1rem", maxWidth: "500px" }}
              >
                <MDBCardBody className="p-5 d-flex flex-column align-items-center mx-auto w-100">
                  <h4 className="text-light text-center">Access denied!</h4>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      )}
    </>
  );
  return <ComponentCard title="Maintenance Dashboard">{content}</ComponentCard>;
}
