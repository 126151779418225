import React, { Component } from "react";
import Alert from "react-bootstrap/Alert";
import {
  Card,
  Row,
  Col,
  Form
} from "react-bootstrap";
import BannerImage from "./new/BannerImage";
import Axios from "axios";
import { postWithCredentials } from "../contexts/AuthContext"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      copy: "",
      success: false,
      fail: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
  }

  async handleSubmit(e) {
    e.preventDefault();
    try {
      await postWithCredentials(SERVER_URL + "sendFAQ", {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
        copy: this.state.copy,
      });
      this.setState({
        name: "",
        email: "",
        message: "",
        copy: "",
        success: true,
      });
      setTimeout(() => this.setState({ success: false }), 3000);
    } catch (e) {
      this.setState({ fail: true });
      setTimeout(() => this.setState({ fail: false }), 3000);
    }
  }

  onInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  onCheckboxChange(event) {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  }

  render() {
    return (
      <Card
        style={{
          border: "none",
          boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Card.Body>
          <BannerImage url="/img/CYN_Cynorix.jpg" />
          <h4 className="mt-3">
            <b>Contact Us!</b>
          </h4>
          <hr className="mt-0" />
          <Row>
            <Col md={12} sm={12} style={{ marginBottom: 10 }}>
              <div className="w-100 d-flex justify-content-center">
                <form className="w-100" onSubmit={this.handleSubmit}>
                  <Form.Group className="mt-3">
                    <Row className="mb-2">
                      <Col
                        xs={12}
                        lg={1}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                        className={`mb-2 mb-lg-0 
                            justify-content-center justify-content-lg-end`}
                      >
                        <label
                          className="form-label"
                          style={{ marginBottom: "0" }}
                          for="form4Example1"
                        >
                          Name
                        </label>
                      </Col>
                      <Col xs={12} lg={11}>
                        <input
                          type="text"
                          id="form4Example1"
                          placeholder="John Doe"
                          class="form-control"
                          value={this.state.name}
                          name="name"
                          onChange={this.onInputChange}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mt-3">
                    <Row className="mb-2">
                      <Col
                        xs={12}
                        lg={1}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                        className={`mb-2 mb-lg-0 
                            justify-content-center justify-content-lg-end`}
                      >
                        <label
                          class="form-label"
                          style={{ marginBottom: "0" }}
                          for="form4Example2"
                        >
                          Email
                        </label>
                      </Col>
                      <Col xs={12} lg={11}>
                        <input
                          type="email"
                          id="form4Example2"
                          class="form-control"
                          placeholder="example@email.com"
                          value={this.state.email}
                          name="email"
                          onChange={this.onInputChange}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mt-3">
                    <Row className="mb-2">
                      <Col
                        xs={12}
                        lg={1}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                        className={`mb-2 mb-lg-0 
                            justify-content-center justify-content-lg-end`}
                      >
                        <label
                          class="form-label "
                          for="form4Example3"
                          style={{ marginBottom: "0" }}
                        >
                          Message
                        </label>
                      </Col>
                      <Col xs={12} lg={11}>
                        <textarea
                          class="form-control"
                          id="form4Example3"
                          rows="4"
                          placeholder="Please type your message here.."
                          value={this.state.message}
                          name="message"
                          onChange={this.onInputChange}
                        ></textarea>
                      </Col>
                    </Row>
                  </Form.Group>
                  <div class="form-check d-flex justify-content-center mb-4">
                    <input
                      class="form-check-input me-2"
                      type="checkbox"
                      id="form4Example4"
                      checked={this.state.check}
                      name="copy"
                      onChange={this.onCheckboxChange}
                    />
                    <label class="form-check-label" for="form4Example4">
                      Send me a copy of this message
                    </label>
                  </div>

                  <button type="submit" class="btn btn-primary btn-block w-100">
                    Send
                  </button>
                  <Alert show={this.state.success} variant="success" style={{ marginTop: "20px" }}>
                    Successfully send the email!
                  </Alert>
                  <Alert show={this.state.fail} variant="danger" style={{ marginTop: "20px" }}>
                    Fail to send Message
                  </Alert>
                </form>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

export default Contact;
