import { Store } from "react-notifications-component";

import { CONNECTION_ERROR, notificationOptions } from "../assets/js/constants";
import * as data from "../assets/json/notifications.json";
const notifications = data

export const NOTIF_WARN = "warning";
export const NOTIF_DANGER = "danger";
export const NOTIF_SUCCESS = "success";

/**
 * Handles a connection error to the Firebase server, emitting an error notification
 * 
 * @param {string} err - Connection error thrown
 */
export function handleConnectionError(err = "") {
    // console.log("handleConnectionError")
    // console.error("Server connection error: " + err);
    emitDangerNotification(notifications.connection.failure);
    throw CONNECTION_ERROR;
}

/**
 * Emits an error message with the heading "Invalid Parameters" and the message given
 * 
 * @param {string} message - The message to display
 */
export function emitWrongParamsNotification(message) {
    emitDangerNotification({
        title: notifications.wrongParam.title,
        message: message
    });
}

/**
 * Emits an error message
 * 
 * @param {Object} object - Object containing title and message of error
 */
export function emitDangerNotification(object) {
    emitNotification(object.title, object.message, NOTIF_DANGER);
}

/**
 * Emits a warning message
 * 
 * @param {Object} object - Object containing title and message of warning
 */
export function emitWarningNotification(object) {
    emitNotification(object.title, object.message, NOTIF_WARN);
}

/**
 * Emits a success message
 * 
 * @param {Object} object - Object containing title and message of success event
 */
export function emitSuccessNotification(object) {
    emitNotification(object.title, object.message, NOTIF_SUCCESS);
}

/**
 * Emits a notification
 * 
 * @param {string} title - Message header
 * @param {string} message - Message body
 * @param {string} type - Error, warning, or success?
 */
function emitNotification(title, message, type) {
    Store.addNotification({
        ...notificationOptions,
        title: title,
        message: message,
        type: type
    });
}
