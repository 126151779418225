import React, { useEffect, useState } from "react";
import Axios from "axios";
import { auth } from "../firebase";
import { getUserFromEmail } from "../functions/getUser";
import { Form, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ComponentCard from "./new/ComponentCard";
import LoadingButton from "./new/LoadingButton";
import FeedbackAlert from "./new/FeedbackAlert";
import { postWithCredentials } from "../contexts/AuthContext"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function Chunk(props) {
  const [productsInfo, setProductsInfo] = useState(null);
  const [serviceName, setServiceName] = useState("");
  const [chunks, setChunks] = useState(0);
  const [rate, setRate] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [loading, setLoading] = useState(true);
  const [feedback, setFeedback] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const userEmail = auth.currentUser.email;
      const userData = await getUserFromEmail(userEmail);
      const userSubId = userData.service;
      for (const subId in productsInfo) {
        if (userSubId === subId) {
          setServiceName(productsInfo[subId].subscription_name);
          const fileEncryptedAttributes =
            productsInfo[subId].attributes.files_encrypted;

          setChunks(fileEncryptedAttributes.chunk);
          setRate(fileEncryptedAttributes.rate / 100);
          setTotalCost(
            fileEncryptedAttributes.chunk * (fileEncryptedAttributes.rate / 100)
          );
          // console.log("done");
        }
      }
      setLoading(false);
    };
    getData();
  }, [productsInfo]);

  useEffect(() => {
    getUserFromEmail("maintenance@cynorix.com")
      .then(({ products }) => {
        setProductsInfo(products);
      })
      .catch((err) => {
        console.log("error when getting products info", err);
        setProductsInfo(null);
      });
  }, []);

  useEffect(() => {
    const getData = async () => {
      const userEmail = auth.currentUser.email;
      const userData = await getUserFromEmail(userEmail);
      const userSubId = userData.service;
      for (const subId in productsInfo) {
        if (userSubId === subId) {
          setServiceName(productsInfo[subId].subscription_name);
          const fileEncryptedAttributes =
            productsInfo[subId].attributes.files_encrypted;

          setChunks(fileEncryptedAttributes.chunk);
          setRate(fileEncryptedAttributes.rate / 100);
          setTotalCost(
            fileEncryptedAttributes.chunk * (fileEncryptedAttributes.rate / 100)
          );
          // console.log("done");
        }
      }
      setLoading(false);
    };
    getData();
  }, [productsInfo]);

  useEffect(() => {
    const checkLimit = async () => {
      const userEmail = auth.currentUser.email;
      const userData = await getUserFromEmail(userEmail);

      await postWithCredentials(SERVER_URL + "check-limit-requirement", {
        userData: userData,
      })
        .then((res) => {
          let serviceInfo = res.data.info;
          //let newavailable = [];
          for (let i = 0; i < serviceInfo.length; i++) {
            if (
              serviceInfo[i].service_limit <= serviceInfo[i].used_limit &&
              serviceInfo[i].allow_to_use === 0
            ) {

            } else {
              setFeedback({
                variant: "warning",
                message: "You are not eligible to buy chunks. Redirecting...",
                loading: true,
              });
              setTimeout(() => {
                navigate("/service-usage-info");
              }, 2000);
            }
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    };
    checkLimit();
  }, [navigate]);

  const handleChange = async (chunk_type) => {
    const userId = auth.currentUser.uid;
    const userEmail = auth.currentUser.email;

    await getUserFromEmail(userEmail).then(async (userData) => {
      let redirect;
      await postWithCredentials(SERVER_URL + "create-chunk-payment", {
        userId: userId,
        userEmail: userEmail,
        userService: userData.service,
        chunk_type: chunk_type,
        customerId: userData.customer_id,
      }).then((res) => {
        redirect = res.data.url;
      });
      setTimeout(() => {
        window.location.replace(redirect);
      }, 250);
    });
  };

  const helpContent = (
    <p className="mb-1">
      When you reach the limit of encryptions you are allowed to use, you will
      be able to access the Buy Chunks page. In this page, you can purchase one
      more “chunk” of encryptions for your plan. Once you click proceed, you
      will be directed to a stripe checkout page to complete your purchase.
    </p>
  );

  return (
    <ComponentCard title={"Buy Chunks"} helpContent={helpContent}>
      {!loading && (
        <Form>
          <Form.Group className="row mb-3">
            <Form.Label column>Subscription Name</Form.Label>
            <Col xs={12} lg={8} xl={9}>
              <Form.Control disabled value={serviceName} />
            </Col>
          </Form.Group>
          <Form.Group className="row mb-3">
            <Form.Label column>Number of Encryptions</Form.Label>
            <Col xs={12} lg={8} xl={9}>
              <Form.Control
                min={0}
                disabled
                type="number"
                required
                value={chunks}
                // onChange={(event) => setNumOfChunks(event.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group className="row mb-3">
            <Form.Label column>Extra Rate Per Encryption</Form.Label>
            <Col xs={12} lg={8} xl={9}>
              <Form.Control disabled value={`$${rate}`} />
            </Col>
          </Form.Group>
          <Form.Group className="row mb-3">
            <Form.Label column>Total Cost</Form.Label>
            <Col xs={12} lg={8} xl={9}>
              <Form.Control disabled value={`$${totalCost}`} />
            </Col>
          </Form.Group>
          <LoadingButton
            // loading={feedback && feedback.loading === true}
            className="w-100"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              handleChange("files_encrypted");
            }}
          >
            Proceed
          </LoadingButton>
          <FeedbackAlert feedback={feedback} className="mt-3 mb-0" />
        </Form>
      )}
    </ComponentCard>
  );
}
