//import Axios from 'axios';

const Axios = require("axios");
const { postWithCredentials } = require('../contexts/AuthContext')
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const getUserFromEmail = async (email) => {
  email = email.toLowerCase();
  return await postWithCredentials(SERVER_URL + "getUserFromEmail", {
    userEmail: email,
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      // console.log(err);
      if (err.response.data.error === "User Not Found") {
        throw new Error("User Not Found");
      } else {
        throw new Error("Error getting user from email");
      }
    });
};

export const getUserFromSecondIndex = async (secondIndex) => {
  return await postWithCredentials(SERVER_URL + "getUserFromSecondIndex", {
    secondIndex: secondIndex,
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      // console.log(err);
      throw new Error("Error getting user from second index");
    });
};

export const getUserFromId = async (user_id) => {
  return await postWithCredentials(SERVER_URL + "getUserFromId", {
    user_id: user_id,
  })
    .then((response) => {
      // console.log(response);
      return response.data;
    })
    .catch((err) => {
      // console.log(err);
      throw new Error("Error getting user from second index");
    });
};

//module.exports = { getUserFromEmail, getUserFromSecondIndex };
