import React, { Component } from "react";

import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Button from "react-bootstrap/esm/Button";

import "./Dialog.css";

/**
 * Handles frontend logic relevant to the 2FA dialog
 */
class AuthDialog extends Component {
  /**
   * Initializes handlers and state
   * @param props - React props
   */
  constructor(props) {
    super(props);
    this.state = { code: "", count: this.props.count };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRepeatSubmit = this.handleRepeatSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleResend = this.handleResend.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  /**
   * Handles submit on 2FA button
   */
  handleSubmit() {
    // let re = /^\w+/;
    // console.log(this.state.code);
    // if (!re.exec(this.state.code)) {

    //   console.error("Invalid code!");
    //   return false;
    // }
    this.props.checkCode(this.state.code);
  }

  /**
   * Handles repeated submit on 2FA button
   */
  handleRepeatSubmit() {
    if (this.state.submit) {
      return;
    } else {
      this.setState({ submit: true });
      this.handleSubmit();
      setTimeout(() => {
        this.setState({ submit: false });
      }, 4000);
    }
  }

  /**
   * Calls cancel2FA from Decryption page on cancel button press
   */
  handleCancel() {
    this.props.cancel();
  }

  /**
   * Calls resend from Decryption page on resend button press
   */
  handleResend() {
    this.props.resend();
  }

  /**
   * Changes the state variables in response to a change to the input fields
   * @param {InputEvent} event - HTML DOM event indicating a change in an input field
   */
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.handleRepeatSubmit();
    }
  };
  /**
   * Renders visible components of auth dialog
   * @returns - JSX Object
   */
  render() {
    if (this.props.verificationType === "p") {
      return (
        <div className="dialog-container p-5">
          <Row>
            <Col>
              <h1>Auth Dialog</h1>
              <p>
                You've been sent a verification code via SMS to your phone to
                ensure you are the correct recipient. Please enter the code
                below.
              </p>
              <input
                type="text"
                className="form-control code-textbox"
                value={this.state.code}
                name="code"
                id="code-input"
                onChange={this.handleInputChange}
              />
              <Button
                className="m-2"
                variant="success"
                onClick={this.handleRepeatSubmit}
              >
                Submit
              </Button>
              {(this.props.enableResend && (
                <Button
                  className="m-2"
                  variant="info"
                  onClick={this.handleResend}
                >
                  Resend Code
                </Button>
              )) || (
                <Button
                  className="m-2"
                  variant="info"
                  disabled={true}
                  title="Code has been resent. Please wait a few minutes"
                  style={{ pointerEvents: "auto" }}
                >
                  Resend Code
                </Button>
              )}
              <Button
                className="m-2"
                variant="danger"
                onClick={this.handleCancel}
              >
                Cancel
              </Button>
              <p className="text-muted">
                Your session will expire in: {this.props.timeLeft}
              </p>
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <div>
          <Row
            className="mb-1 row"
            style={{
              textAlign: "left",
              justifyContent: "left",
              paddingLeft: "10px",
            }}
          >
            <p className="mb-1">
              Please enter the verification code sent to your phone number:
              +1*******{this.props.number.slice(-3)}
            </p>
          </Row>
          <Row
            className="mb-1 row"
            style={{
              textAlign: "left",
              justifyContent: "left",
              paddingLeft: "10px",
            }}
          >
            <b className="mb-1">
              {this.props.status === "failed" ? (
                <p>
                  {" "}
                  Your file is deleted, please contact the sender and request to
                  encrypt again
                </p>
              ) : (
                <p className="my-2">
                  Note: Your file will be automatically deleted if you make 3
                  incorrect attempts
                </p>
              )}
            </b>
          </Row>
          <Row className="mb-3 row">
            <label
              xs={12}
              lg={2}
              style={{
                display: "flex",
                alignItems: "center",
                padding: 0,
              }}
              className="col-md-2 col-form-label"
            >
              Confirmation Code
            </label>
            <input
              type="text"
              className="form-control code-textbox"
              value={this.state.code}
              name="code"
              id="code-input"
              onChange={this.handleInputChange}
              onKeyDown={this.handleKeyDown}
            />
            <Col xs={12} lg={2}>
              {(this.props.enableResend && this.props.status !== "failed" && (
                <Button className="m-2" onClick={this.handleResend}>
                  Resend Code
                </Button>
              )) || (
                <Button
                  className="m-2"
                  disabled={true}
                  title="Code has been resent. Please wait a few minutes"
                  style={{ pointerEvents: "auto" }}
                >
                  Resend Code
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {this.props.status === "failed" ? (
                <Button
                  className="mb-2"
                  style={{ width: "100%" }}
                  onClick={this.handleRepeatSubmit}
                  disabled={true}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  className="mb-2"
                  style={{ width: "100%" }}
                  onClick={this.handleRepeatSubmit}
                >
                  Submit
                </Button>
              )}
            </Col>
          </Row>
        </div>
      );
    }
  }
}

export default AuthDialog;
