import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCrKct0mJKqKB5md0EjdYtifI3os4QeBLw",
  authDomain: "cynorix-msfs-test.firebaseapp.com",
  databaseURL: "https://cynorix-msfs-test-default-rtdb.firebaseio.com",
  projectId: "cynorix-msfs-test",
  storageBucket: "cynorix-msfs-test.appspot.com",
  messagingSenderId: "394312835216",
  appId: "1:394312835216:web:46d1ddd8fcde19838b7815"
};

const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();
export default app;
