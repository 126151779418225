import React from "react";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';

const HomePageHelp = () => {
    return (
        <div style={{backgroundColor: 'rgba(0,0,0)', backgroundSize: 'cover', minHeight: '100vh', padding: '3%'}}>
            <Link to='/'><Button className="home-btn custom-btn" style={{position: 'absolute', top: '0', right: '0', marginTop: '3.5%', marginRight: '5%', backgroundColor: '#800000'}}>Go back</Button></Link>
            <h1>Help</h1>
            <ul className="h5">
                <li>This web-hosted software tool allows a “sender” to encrypt a file (or a collection of files) and share the outcome (in an encrypted form) with a receiver. </li>
                <li>Encryption is performed using industry standard AES (Advanced Encryption System) with a random key of size 256 bits.</li>
                <li>The key for encryption is generated within sender’s computer (or tablet/handheld), never leaves sender’s device, and is erased upon completion of encryption. </li>
                <li>Files to be encrypted are gathered in a folder, compressed and the resulting ZIP folder is encrypted. Compression is used to enhance the security of encryption mechanism by removing redundancies within files to be encrypted.</li>
                <li>Sender finds the encrypted Zipped folder in her/his download folder.</li>
                <li>Sender can rely on a sharing method of choice, e.g., sending the encrypted Zipped folder as an email attachment, sharing it through a cloud storage service such as G-drive, OneDrive, Dropbox, Box, etc., sending it through a chat application, etc.</li>
                <li>Encrypted Zipped folder is not stored in any location other than the sender’s device used to encrypt the files, or the cloud storage service used by the sender for sharing of the encrypted Zipped folder.</li>
                <li>Sender can select:</li>
                <ul>
                    <li>The maximum number of times the Zipped folder can be decrypted.</li>
                    <li>A date after which the Zipped folder cannot be decrypted.</li>
                    <li>An option to receive an email notification each time the Zipped folder is decrypted, including the identity of the individual who has decrypted the file.</li>
                    <li>An option requiring receivers to enter a one-time password sent as an SMS to their cell phone when they attempt to decrypt the Zipped folder, where cell phone number(s) of each receiver is provided by the sender.</li>
                    <li>An option requiring receivers to enter a one-time password sent to their email address when they attempt to decrypt the Zipped folder (email address(es) of each receiver is provided by the sender).</li>
                </ul>
                <li>To decrypt an encrypted Zipped folder, the receiver is asked to enter the phone number or the email address specified by the sender. If the provided phone number or the provided email address is correct (same as what is specified by the sender), then the receiver will receive a one-time password (through SMS or email) that is required for decrypting the Zipped folder. </li>
                <li>Note: Information related to a Zipped folder are stored at Firebase  (a cloud database service provided by Google).   Communication with Firebase server is  secured using industry standard https/TLS.</li>
                <li><b>Note: In case the system cannot locate the file, please refresh the browser page. </b></li>
            </ul>
        </div>
    )
}

export default HomePageHelp;
