import React, { useEffect, useState } from "react";
import {
  Button,
  Alert,
  Modal,
  Col,
  ToggleButtonGroup,
  ToggleButton,
  Row,
  Navbar,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { auth } from "../../firebase";
import { getUserFromEmail } from "../../functions/getUser";
import { useCookies } from "react-cookie";
import "./ShowServiceClass.css";
import check from "../../assets/icons/svg/check.png";
import { Card } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Stack } from "react-bootstrap";
import { PLAN_STATE_PAID, PLAN_STATE_SPONSORED } from "./SideBar";
import ComponentCard from "./ComponentCard";
import { useAuth,postWithCredentials,getWithSecureCredentials } from"../../contexts/AuthContext";


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ShowServiceClass() {
  const [userData, setUserData] = useState(null);
  const [productsInfo, setProductsInfo] = useState(null);
  const [currentSubID, setCurrentSubID] = useState("");
  const [isDiscounted, setIsDiscounted] = useState(false);


  // get user information
  useEffect(() => {
    setUserData(null);
    getUserFromEmail(auth.currentUser.email)
      .then((data) => {
        setCurrentSubID(data.service);
        // console.log("THIS IS USER DATA", data);
        setUserData(data);
      })
      .catch((err) => {
        console.log("error occurred when getting user data", err);
      });
    getCancelServiceStatus(auth.currentUser.email)
      .then((isCancel) => {
        setUserCanceledService(isCancel);
      })
      .catch((err) => {
        console.log("error occured while getting cancel status", err);
      });
  }, []);

  // get initial products info
  useEffect(() => {
    getUserFromEmail("maintenance@cynorix.com")
      .then(({ products }) => {
        setProductsInfo(products);
      })
      .catch((err) => {
        console.log("error when getting products info", err);
        setProductsInfo(null);
      });
  }, []);

  useEffect(() => {
    // Fetch the flag from the server when the component mounts
    getWithSecureCredentials(SERVER_URL+'isUserDiscounted', {headers: {"Content-Type" : "application/json"}})
    .then((res) => {
        console.log(res)
        setIsDiscounted(res.data.couponFlag);
        if(res.data.couponFlag){
          console.log("inside if", res.data.couponFlag)

        }
        
    })
    .catch((err) => {
        console.error("Error fetching discounted flag:", err);
    });
  }, []);

  const [monthly, setMonthly] = useState(true);
  const [serviceName, setServiceName] = useState("");
  const [serviceID, setServiceID] = useState("");
  const [serviceSelected, setServiceSelected] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [userCanceledService, setUserCanceledService] = useState(false);
  const [waitSucess, setWaitSucess] = useState(false);

  const [cookies, setCookie] = useCookies(["name"]);

  const navigate = useNavigate();

  ////////
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  ////////

  const getCancelServiceStatus = async (email) => {
    return await postWithCredentials(SERVER_URL + "checkCancelServiceStatus", {
      userEmail: email,
    })
      .then((response) => {
        return response.data.canceled;
      })
      .catch((err) => {
        // console.log(err);
        //throw new Error();
      });
  };

  const setUserService = async (Id) => {
    const uid = auth.currentUser.uid;
    const userEmail = auth.currentUser.email;
    let subscribed = false;
    let subId = "";
    await getUserFromEmail(userEmail).then((userData) => {
      // determine if user is in FPP 0 or FPP 1
      // Double checking if user FPP is 1 or not. This would handel the payment
      if (userData.subscription_id && userData.FPP === 1) {
        subscribed = true;
        subId = userData.subscription_id;
      }
      // if (userData.subscription_id) {
      // 	subscribed = true;
      // 	subId = userData.subscription_id;
      // }
    });

    if (subscribed) {
      await updateSubscription(subId, Id, uid, userEmail);
      setTimeout(() => {
        navigate(`/service-usage-info`);
      }, 1000);
    } else {
      setTimeout(() => {
        navigate(`/create-subscription/?class_id=${Id}`);
      }, 1000);
    }
  };

  const updateSubscription = async (subId, classId, userId, userEmail) => {
    await postWithCredentials(SERVER_URL + "updateSubscription", {
      subId,
      classId,
      userId,
      userEmail,
      cancellingSubs: false,
    })
      .then((response) => {
        // console.log("Subscription Successfully Updated");
        setSuccess("Successfully Switched Service");
        // These line wait till 2 mins to allow next subscription
        var date = new Date();
        date.setTime(date.getTime() + 60 * 2000);
        setCookie("name", "newName", { expires: date, path: "/" });
        //console.log(waitSucess);
        setWaitSucess(true);
      })
      .catch((err) => {
        // console.log(err);
        setError("Failed to Switch Service, Please try after some time!");
        //throw new Error('Failed to create/update subscription')
      });
    // const index = generateEmailIndex(currentUser.email);
    // await sendChangeSubTier(currentUser.email, index);
  };

  const updateBillingPeriod = (period) => {
    setMonthly(!monthly);
    // updateSelectedProduct(serviceID, serviceName)
  };

  const updateSelectedProduct = (id, name, isSelected = true) => {
    setServiceID(id);
    setServiceSelected(isSelected);
    setServiceName(name);
  };


  if (userData === null || productsInfo === null) {
    return null;
  }

  function generateProducts() {
    // filter out billing period and free trials
    console.log(productsInfo)
    const displayPlans = Object.entries(productsInfo).filter(
      ([key, value]) =>
      (!value.isDiscount || value.isDiscount === undefined) && value.billing_period === (monthly ? "1" : "12") &&
        value.basic_rate !== 0
    );
    // billing period from 0/1 => year/month
    const billingPeriodToStr = (val) => (val === "1" ? "month" : "year");
    // console.log(serviceID);
    // console.log(displayPlans);

    if (displayPlans.some(([key, value]) => value.attributes === undefined)) {
      console.error(
        "A plan does not have a attributes field. You should check the database"
      );
      return null;
    }

    return displayPlans.map(([key, value]) => (
      <Col sm={12} md={6} className={"mb-2"} key={key}>
        <Card
          style={{
            boxShadow:
              key === serviceID ? "0px 0px 5px 0px rgba(0, 0, 0, 0.25)" : "",
            height: "100%",
          }}
        >
          <Card.Body className="pt-4 pb-0">
            <Card.Subtitle className="text-uppercase small text-center">
              <b>{value.subscription_name}</b>
            </Card.Subtitle>
            <Card.Title className="text-center mt-1">
              {value.basic_rate} {value.currency} per{" "}
              {billingPeriodToStr(value.billing_period)}
            </Card.Title>
            {key === currentSubID ? (
              <Card.Subtitle
                className="small text-center"
                style={{ color: "darkgreen" }}
              >
                <b>(Current Subscription)</b>
              </Card.Subtitle>
            ) : null}
          </Card.Body>
          <hr />
          <Card.Body className="h-100">
            {/* Plan Options */}
            <Stack className="align-items-start mb-4" gap={3}>
              {value.attributes.files_encrypted ? (
                <>
                  <span>
                    <img className="icon mx-1" src={check} alt="check" />
                    Number of Files that can be shared:{" "}
                    <b>{value.attributes.files_encrypted.limit}</b>
                  </span>
                  <span>
                    <img className="icon mx-1" src={check} alt="check" />
                    Rate for next{" "}
                    <b>
                      {value.attributes.files_encrypted.chunk}
                    </b> encryptions:{" "}
                    <b>
                      $
                      {(parseInt(value.attributes.files_encrypted.rate) / 100) *
                        value.attributes.files_encrypted.chunk}
                    </b>
                  </span>
                </>
              ) : null}
              <span>
                {/* {value.attributes.messages_sent ? ( */}
                <img className="icon mx-1" src={check} alt="check" />
                {/* // ) : (
                //   <img className="icon mx-1" src={wrong} alt="wrong" />
                // )} */}
                SMS & Email
              </span>
              {/* <span>
                {value.attributes.emails_sent ? (
                  <img className="icon mx-1" src={check} alt="check" />
                ) : (
                  <img className="icon mx-1" src={wrong} alt="wrong" />
                )}
                Email
              </span> */}
            </Stack>
            {/* Selection Button */}
          </Card.Body>
          <Card.Body>
            <Button
              className="mb-0 w-100"
              active={key === serviceID}
              disabled={key === currentSubID}
              onClick={() => {
                if (key === serviceID) {
                  // allow user to deselect a plan
                  updateSelectedProduct("", "", false);
                } else {
                  updateSelectedProduct(key, value.subscription_name);
                }
              }}
            >
              {key === serviceID ? "Deselect" : "Select"}
            </Button>
          </Card.Body>
        </Card>
      </Col>
    ));
  }

  function generateDiscountedProducts() {
    // filter out billing period and free trials
    console.log("generated discounted products", productsInfo)
    const displayPlans = Object.entries(productsInfo).filter(
      ([key, value]) =>
      value.isDiscount && value.billing_period === (monthly ? "1" : "12") &&
        value.basic_rate !== 0
    );
    // billing period from 0/1 => year/month
    const billingPeriodToStr = (val) => (val === "1" ? "month" : "year");
    // console.log(serviceID);
    // console.log(displayPlans);

    if (displayPlans.some(([key, value]) => value.attributes === undefined)) {
      console.error(
        "A plan does not have a attributes field. You should check the database"
      );
      return null;
    }

    return displayPlans.map(([key, value]) => (
      <Col sm={12} md={6} className={"mb-2"} key={key}>
        <Card
          style={{
            boxShadow:
              key === serviceID ? "0px 0px 5px 0px rgba(0, 0, 0, 0.25)" : "",
            height: "100%",
          }}
        >
          <Card.Body className="pt-4 pb-0">
            <Card.Subtitle className="text-uppercase small text-center">
              <b>{value.subscription_name}</b>
            </Card.Subtitle>
            <Card.Title className="text-center mt-1">
              {value.basic_rate} {value.currency} per{" "}
              {billingPeriodToStr(value.billing_period)}
            </Card.Title>
            {key === currentSubID ? (
              <Card.Subtitle
                className="small text-center"
                style={{ color: "darkgreen" }}
              >
                <b>(Current Subscription)</b>
              </Card.Subtitle>
            ) : null}
          </Card.Body>
          <hr />
          <Card.Body className="h-100">
            {/* Plan Options */}
            <Stack className="align-items-start mb-4" gap={3}>
              {value.attributes.files_encrypted ? (
                <>
                  <span>
                    <img className="icon mx-1" src={check} alt="check" />
                    Number of Files that can be shared:{" "}
                    <b>{value.attributes.files_encrypted.limit}</b>
                  </span>
                  <span>
                    <img className="icon mx-1" src={check} alt="check" />
                    Rate for next{" "}
                    <b>
                      {value.attributes.files_encrypted.chunk}
                    </b> encryptions:{" "}
                    <b>
                      $
                      {(parseInt(value.attributes.files_encrypted.rate) / 100) *
                        value.attributes.files_encrypted.chunk}
                    </b>
                  </span>
                </>
              ) : null}
              <span>
                {/* {value.attributes.messages_sent ? ( */}
                <img className="icon mx-1" src={check} alt="check" />
                {/* // ) : (
                //   <img className="icon mx-1" src={wrong} alt="wrong" />
                // )} */}
                SMS & Email
              </span>
              {/* <span>
                {value.attributes.emails_sent ? (
                  <img className="icon mx-1" src={check} alt="check" />
                ) : (
                  <img className="icon mx-1" src={wrong} alt="wrong" />
                )}
                Email
              </span> */}
            </Stack>
            {/* Selection Button */}
          </Card.Body>
          <Card.Body>
            <Button
              className="mb-0 w-100"
              active={key === serviceID}
              disabled={key === currentSubID}
              onClick={() => {
                if (key === serviceID) {
                  // allow user to deselect a plan
                  updateSelectedProduct("", "", false);
                } else {
                  updateSelectedProduct(key, value.subscription_name);
                }
              }}
            >
              {key === serviceID ? "Deselect" : "Select"}
            </Button>
          </Card.Body>
        </Card>
      </Col>
    ));
  }

  const helpContent = (
    <p className="mb-1">
      For users that want to commit to a paid plan, they can click Show
      Subscription Tiers. There, they can compare and choose the best plan for
      them. Once selected, the user can click Purchase Subscription Tier at the
      bottom of the screen. The user will be asked to confirm and click Pay Now
      to complete their purchase. This will take them to the checkout page to
      complete their purchase. Each subscription comes with a different number
      of files that can be shared, and a rate for the next “chunk” of
      encryptions if they require more file encryptions.
    </p>
  );

  return (
    <>
      <ComponentCard
        title={"Choose the Best Plan for You"}
        helpContent={helpContent}
      >
        <div className="d-flex justify-content-center mb-3">
          <ToggleButtonGroup
            type="radio"
            name="options"
            value={monthly ? 1 : 2}
          >
            <ToggleButton
              onClick={updateBillingPeriod}
              value={1}
              disabled={monthly}
            >
              Monthly Billing
            </ToggleButton>
            <ToggleButton
              onClick={updateBillingPeriod}
              value={2}
              disabled={!monthly}
            >
              Annual Billing
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <Row>{isDiscounted? generateDiscountedProducts():generateProducts() }</Row>
        <Navbar sticky="bottom">
          <Container
            className="p-3 mb-2 bg-white rounded"
            style={{
              boxShadow: "0px 0px 15px 5px rgba(0, 0, 0, 0.25)",
            }}
          >
            {serviceSelected ? (
              <>
                <Navbar.Brand>
                  {productsInfo[serviceID].subscription_name}
                </Navbar.Brand>
                <Navbar.Text className="me-auto">
                  {productsInfo[serviceID].basic_rate}{" "}
                  {productsInfo[serviceID].currency} per{" "}
                  {productsInfo[serviceID].billing_period === "1"
                    ? "month"
                    : "year"}
                </Navbar.Text>
              </>
            ) : (
              <Navbar.Brand>No Service Selected</Navbar.Brand>
            )}
            <Stack direction="horizontal">
              <Button
                className="ms-auto me-2"
                variant="secondary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </Button>
              <Button disabled={!serviceSelected} onClick={handleShow}>
                Purchase Subscription Tier
              </Button>
            </Stack>
          </Container>
        </Navbar>
      </ComponentCard>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* I have added cookies too see what would happen if there already exist */}
          {userData.FPP !== PLAN_STATE_PAID &&
            userData.FPP !== PLAN_STATE_SPONSORED &&
            cookies.name !== "newName" && (
              <div>
                If you want to upgrade your subscription tier, please click the
                pay now button to continue. After payment, your current
                subscription tier will be upgraded to
                <strong>{" " + serviceName}</strong> immediately.
              </div>
            )}
          {userData.FPP === PLAN_STATE_PAID && cookies.name !== "newName" && (
            <div>
              If you want to switch your subscription tier, please click the
              confirm button to continue. After confirmation, your current
              subscription tier will be switched to
              <strong>{" " + serviceName}</strong> at the beginning of next
              month.
            </div>
          )}
          {userData.FPP === PLAN_STATE_SPONSORED && cookies.name !== "newName" && (
            <div>
              If you want to switch to a subscription tier, please click the
              confirm button to continue. After confirmation, your sponsorship
              will end and your current subscription tier will be switched to
              <strong>{" " + serviceName}</strong> at the beginning of next
              month.
            </div>
          )}
          {cookies.name !== "newName" && userCanceledService && (
            <div>
              <strong>Notice:</strong> Since your subscription for next month
              was canceled previously, if you confirm to select this class of
              service, your subscription will be resumed.
            </div>
          )}
          {cookies.name === "newName" && waitSucess !== true && (
            <div>
              You have recently changed your subscription status. Please try
              after one hour.
            </div>
          )}
          {success && <Alert variant="success">{success}</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
        </Modal.Body>
        <Modal.Footer>
          {/* Ternary operator to see if a user has changed subscription recently  */}

          {cookies.name === "newName" ? (
            <Button variant="primary" onClick={handleClose}>
              Go back
            </Button>
          ) : (
            <>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setUserService(serviceID);
                }}
              >
                {userData.FPP === PLAN_STATE_PAID ? "Confirm" : "Pay now"}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
