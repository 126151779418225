/**
 * Basic notification options
 */
export const notificationOptions = {
  insert: "bottom",
  container: "top-left",
  dismiss: {
    duration: 3000,
    onScreen: true,
    click: false,
    pauseOnHover: false,
  }
}

/**
 * URL to firebase cloud function that acts as an endpoint for the Mongo database
 */
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/**
 * The message sent by the server on a successful insertion into the Mongo database
 */
export const SERVER_ENCRYPTION_SUCCESS = "success";

/**
 * The message sent by the server on a succesful deletion from the Mongo database
 */
export const SERVER_DECRYPTION_SUCCESS = "success";

/**
 * The message sent by the server on a failed attempt at removing from the Mongo database
 */
export const SERVER_DECRYPT_FAILURE = "failed";

/**
 * The message sent by the server when the given file is not found
 */
export const SERVER_DECRYPT_NOT_FOUND = "not-found";

/**
 * The message sent by the server when the given file has no uses left
 */
export const SERVER_DECRYPT_NO_USES = "no-uses";

/**
 * The message sent by the server when the decrypted file does not match what is on the database
 */
export const SERVER_DECRYPT_BAD_PROOF = "badProof";

/**
 * The message sent by the server for the status key when verification is needed for decryption
 */
export const SERVER_2FA_NEED_VERIFICATION = "verify";

/**
 * The message sent by the server for the status key when the user is not allowed to decrypt a file
 */
export const SERVER_2FA_NOT_ALLOWED = "not-allowed";

/**
 * The message sent by the server when 2FA is not needed to decrypt the file
 */
export const SERVER_2FA_NOT_NEEDED = "no-verify";

/**
 * The message sent by the server for the status key when a given file cannot be found
 */
export const SERVER_2FA_STATUS_FAIL = "failed";

/**
 * The message sent by the server during 2FA if a file cannot be found.
 */
export const SERVER_2FA_NOT_FOUND = "not-found";

/**
 * The message sent by the server when the 2FA request has been successfully approved
 */
export const SERVER_2FA_APPROVED = "approved";

/**
 * The message sent by the server when the 2FA status is pending (still awaiting a code)
 */
export const SERVER_2FA_PENDING = "pending";

/**
 * The message sent by the server when the 2FA request has been canceled
 */
export const SERVER_2FA_CANCELED = "canceled";

/**
 * The message sent by the server when the user is found in the db
 */
export const SERVER_USER_FOUND = "found";

/**
 * The message sent by the server when the user cannot be found
 */
export const SERVER_USER_NOT_FOUND = "not-found";

/**
 * The message sent by the server when it finds the given subscription
 */
export const SERVER_SUBSCRIPTION_FOUND = "found";

export const SERVER_GETUSES_SUCCESS = "success";

/**
 * The message sent by the server when it cannot find the given subscription
 */
export const SERVER_SUBSCRIPTION_NOT_FOUND = "not-found";

/**
 * The message sent to the frontend when the server finds the user's information
 */
export const USER_DATA_FOUND = "userData.found";

/**
 * The message sent to the frontend when the server cannot find the user's id
 */
export const USER_DATA_NOT_FOUND = "userData.notFound";

/**
 * The message sent to the frontend when the server finds the given subscription
 */
export const USER_DATA_SUBSCRIPTION_FOUND = "userData.subscription.found";

/**
 * The message sent to the frontend when the server cannot find the given subscription
 */
export const USER_DATA_SUBSCRIPTION_NOT_FOUND = "userData.subscription.notFound";

/**
 * The message sent to the frontend when decryption requires verification
 */
export const DECRYPTION_NEEDS_VERIFICATION = "decryption.2FA.askForVerify";

/**
 * The message sent to the frontend when the phone for the 2FA request does not exist on the Mongo database
 */
export const DECRYPTION_PHONE_NOT_RECOGNIZED = "decryption.2FA.badPhone";

/**
 * The message sent to the frontend when the given file does not need 2FA
 */
export const DECRYPTION_NO_VERIFICATION = "decryption.2FA.noVerificationNeeded";

/**
 * The message sent to the frontend when the file to decrypt is not found in the database
 */
export const DECRYPTION_NOT_FOUND = "decryption.notFound";

/**
 * The message sent to the frontend when the given file has exceeded its limit on decryptions
 */
export const DECRYPTION_NO_USES = "decryption.noUses";

/**
 * The message sent to the frontend when the proof for decryption is wrong
 */
export const DECRYPTION_BAD_PROOF = "decryption.badProof";

/**
 * The message sent to the frontend when the decryption is successful
 */
export const DECRYPTION_SUCCESS = "decryption.success";

/**
 * The message sent to the frontend when the file has been successfully added to the database
 */
export const ENCRYPTION_SUCCESS = "encryption.success";

/**
 * The maximum number of tries that a user can attempt 2FA
 */
export const TWO_FACTOR_MAX_TRIES = 3;

/**
 * The message sent to the frontend when the 2FA attempt fails
 */
export const TWO_FACTOR_ATTEMPT_FAIL = "2FA.attemptFail";

/**
 * The message sent to the frontend when the user has attempted 2FA too many times
 */
export const TWO_FACTOR_TRIES_EXCEEDED = "2FA.triesExceeded";

/**
 * The message sent to the frontend when the 2FA request has been approved
 */
export const TWO_FACTOR_APPROVED = "2FA.approved";

/**
 * The message sent to the frontend when the 2FA request has been canceled by Twilio
 */
export const TWO_FACTOR_CANCELED = "2FA.canceled";

/**
 * The message sent to the frontend when the 2FA request has been canceled by the user and the operation is successful
 */
export const TWO_FACTOR_CANCEL_SUCCESS = "2FA.cancelSuccess";

/**
 * The message sent to the frontend when the backend cannot reach the server
 */
export const CONNECTION_ERROR = "connection.failed";

/**
 * The message sent to the frontend when the notification are sent successfully
 */
export const NOTIFICATION_SUCCESS = "notification.success";

/**
 * The message sent to the frontend when the notification are fail to be sent
 */
export const NOTIFICATION_FAILURE = "notification.failure";