import React, { useEffect, useState } from "react";
import { Alert, Button, Stack } from "react-bootstrap";
import { auth } from "../../firebase";
import { getUserFromEmail } from "../../functions/getUser";
import "./dashboard.css";
import ComponentCard from "./ComponentCard";
import { useNavigate } from "react-router-dom";

import "../App.css";

export default function Dashboard() {
  const [hasAccess, setHasAccess] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await renderAccessStatus();
    })();
  }, []);

  const renderAccessStatus = async () => {
    try {
      const userEmail = auth.currentUser.email;
      const userData = await getUserFromEmail(userEmail);
      setHasAccess(userData.FPP !== 0 && userData.FPP !== 4);
    } catch (error) {
      alert("Failed to render the page.");
    }
  };

  const body = (
    <div style={{ gap: 10, display: "flex", flexDirection: "column" }}>
      {!hasAccess ? (
        <Alert variant="secondary" className="mb-1 bg-light">
          You are not subscribed to a free trial or a subscription, you can only
          use our decryption service. Please purchase one of them in order to
          use other services.
          <Stack direction="horizontal" className="mt-3" gap={3}>
            <Button
              className="w-100"
              onClick={() => {
                navigate("/free-trial");
              }}
            >
              Free Trial
            </Button>
            <Button
              className="w-100"
              onClick={() => {
                navigate("/upgrade-service");
              }}
            >
              Upgrade Service
            </Button>
          </Stack>
        </Alert>
      ) : null}
      <Button
        // href='https://encryption-with-expiry.web.app/encrypt'
        disabled={!hasAccess}
        href="/encrypt"
        onClick={() => {
          navigate("/encrypt");
        }}
      >
        Encrypt Files
      </Button>
      <Button
        onClick={() => {
          navigate("showSaveFilePicker" in window ? "/decrypt" : "/decrypt2");
        }}
      >
        Decrypt Files
      </Button>
    </div>
  );

  const helpContent = (
    <p className="mb-1">
      This page in the application, shown below, allows the user to select{" "}
      <b>Encrypt Files</b> or <b>Decrypt Files</b>. The buttons will link to the{" "}
      <b>Encrypt Files</b> and <b>Decrypt Files</b> pages respectively. You can{" "}
      <b>Encrypt Files</b> if you have a subscription or free trial active.
      Otherwise, you are only permitted to <b>Decrypt Files</b>.
    </p>
  );

  return (
    <ComponentCard title="Dashboard" helpContent={helpContent}>
      {body}
    </ComponentCard>
  );
}
