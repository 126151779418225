import { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function SignInModal(props) {
  // integrate property into react state
  const [isAuthorizing, setIsAuthorizing] = useState(props.isAuthorizing);
  useEffect(() => {
    setIsAuthorizing(props.isAuthorizing);
  }, [props.isAuthorizing]);
  const navigate = useNavigate();

  const closeModal = () => {
    setTimeout(() => {
      navigate('/');
    }, 500);
  };

  if (isAuthorizing) {
    return (
      <Modal show={true}>
        <Modal.Header>
          <Modal.Title>Sign In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Attempting to authorize user <br />
            <br />
            Please wait as you are being signed in.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => {
              props.handleAuth();
            }}
            disabled
          >
            <Spinner size={"sm"} /> Sign In
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return (
    <Modal show={true} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Access Denied</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You are signed out. <br />
          <br />
          Please sign in as the authorized user to access this page.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={(e) => {
            props.handleRegistration();
          }}
        >
          Sign Up
        </Button>
        <Button
          onClick={(e) => {
            props.handleAuth();
          }}
        >
          Sign In
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
