import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from 'react-router-dom';
import App from "./components/App";
//import "bootstrap/dist/css/bootstrap.min.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Axios from "axios";
import { AuthProvider } from "./contexts/AuthContext";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

(async () => {
  const { publishableKey } = await Axios.get(SERVER_URL + "config"
  ).then((response) => {
    return response.data;
  });
  const stripePromise = await loadStripe(publishableKey);

  ReactDOM.render(
    <React.StrictMode>
      <Elements stripe={stripePromise}>
        <AuthProvider>
          <Router>
            <App/>
          </Router>
        </AuthProvider>
      </Elements>
    </React.StrictMode>,
    document.getElementById("root")
  );
})();
