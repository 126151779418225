import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import { getDatabase, ref, set } from "firebase/database";
import sha256 from "crypto-js/sha256";
import axios from "axios";
import { getUserFromEmail } from "../functions/getUser";

const AuthContext = React.createContext();
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  const signup = async (email, password, firstName, lastName) => {
    email = email.toLowerCase();

    try {
      const cred = await auth.createUserWithEmailAndPassword(email, password);
      const user = cred.user;
      const userId = user.uid;
      // create user in database
      const db = getDatabase();

      // if user is maintenance
      if (email === "maintenance@cynorix.com") {
        return set(ref(db, "users/" + userId), {
          user_id: userId,
          email: email,
          products: {},
          products_updates: {},
        });
      }

      // or if the user is not maintenance
      const second_index = generateEmailIndex(email);
      await postWithCredentials(`${SERVER_URL}registerUser`, {
        user_id: userId,
        email,
        second_index,
        first_name: firstName,
        last_name: lastName,
      });

      // console.log("finished calling register user");
    } catch (e) {
      var errorCode = e.code;
      // console.log(err);
      return errorCode;
    }
  };

  const generateEmailIndex = (email) => {
    email = email.toLowerCase();
    return sha256(email).toString();
  };

  const login = async (email, password) => {
    email = email.toLowerCase();
    return auth
      .signInWithEmailAndPassword(email, password)
      .then(async (userCredential) => {
        const { deletion_date } = await getUserFromEmail(email);
        if (deletion_date) {
          auth.signOut();
          return "requires_activation";
        }
      })
      .catch((err) => {
        var errorCode = err.code;
        return errorCode;
      });
  };

  const logout = () => {
    return auth.signOut();
  };

  const resetPassword = (email) => {
    email = email.toLowerCase();
    return auth.sendPasswordResetEmail(email);
  };

  const updateEmail = (email) => {
    email = email.toLowerCase();
    return currentUser.updateEmail(email);
  };

  const updatePassword = (password) => {
    return currentUser.updatePassword(password);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    generateEmailIndex,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

async function getAuthUser() {
  const listen = () => new Promise((resolve) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
          resolve(user);
          unsubscribe();
      });
  });
  return await listen();
}

export async function postWithCredentials(link, data, config) {
  const user = await getAuthUser();
  if (!user) {
      return await axios.post(link, data, config);
  }
  const token = await user.getIdToken();
  if (!config) {
      config = {};
  }
  config.headers = { Authorization: `Bearer ${token}` };
  return await axios.post(link, data, config);
}

export async function getWithSecureCredentials(link, config) {
  const user = await getAuthUser();
  if (!user) {
      return await axios.get(link, config);
  }
  const token = await user.getIdToken();

  config.headers = { Authorization: `Bearer ${token}` };
  return await axios.get(link, config);
}

