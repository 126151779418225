import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Form,
  Button,
  Card,
  Alert,
  Spinner,
  Row,
  Col,
} from "react-bootstrap";
import { auth } from "../firebase";
import { verifyCode } from "../functions/verify";
import ComponentCard from "./new/ComponentCard";
import "./OAuth.css";
import LoadingButton from "./LoadingButton";
import { postWithCredentials } from "../contexts/AuthContext"
import { RegWithCyAuthPopup } from "../services/cyauthHelper";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function OAuth() {
  const [data, setData] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [confirmIntention, setConfirmIntention] = useState(true);
  const [gettingQRCode, setGettingQRCode] = useState(false);
  const [has2FA , setHas2FA ] = useState(false);
  const [cyAuthId, setCyAuthId] = useState("");
  const [authType, setAuthType] = useState("none");
  const navigate = useNavigate();

  const renderQRCode = useCallback(async () => {
    setGettingQRCode(true);
    try {
      const userId = auth.currentUser.uid;
      const qrCode = await generateQRCode(userId);
      setData(qrCode);
    } catch (e) {
      setError("Error Rendering QR Code. Please try refreshing.");
      // console.log(e);
    }
    setGettingQRCode(false);
  }, [setData, setError, setGettingQRCode]);

  // user confirmed they want Google => render QR code
  useEffect(() => {
    if (confirmIntention) {
      renderQRCode();
    }
  }, [confirmIntention, renderQRCode]);

  // get the user's 2FA info (which authType)
  useEffect(() => {
    postWithCredentials(SERVER_URL + "have2fa", {
      user_id: auth.currentUser.uid,
    })
      .then((res) => {
        setHas2FA(res.data.haveSecret);
        setCyAuthId(res.data.CyAuthId) // CyAuthId is actually just userId (checked the backend route)
        setAuthType(res.data.authType);
      });
  }, []);

  const generateQRCode = async (id) => {
    return await postWithCredentials(SERVER_URL + "qrcode", {
      userId: id,
    })
      .then((response) => {
        // console.log('Posted QR code');
        if (response.data.data === {}) {
          throw Error("No QR Code");
        }
        return response.data.data;
      })
      .catch((err) => {
        // console.log(err);
        throw err;
      });
  };

  // verifying the code from Google Authenticator signup
  const verify2FA = async () => {
    try {
      const userId = auth.currentUser.uid;
      const verification = await verifyCode(code, userId);
      if (verification) {
        setSuccess("Successfully Set Up 2-Factor Authentication");
        setError("");
        setTimeout(() => {
          if (auth.currentUser.email === "maintenance@cynorix.com") {
            navigate("/maintenance-dashboard");
          } else {
            navigate("/dashboard");
          }
        }, 1000);
      } else {
        setError("The code is incorrect");
      }
    } catch (err) {
      // console.log(err);
      setError("Error verifying the code, please try again");
      window.location.reload();
    }
  };

  const setCyAuthVars = async () => {
    try {
      const userId = auth.currentUser.uid;
      await postWithCredentials(`${SERVER_URL}setCyAuthVars`).then((response) => {
        console.log(response)
        if (response.data.success) {
          window.location.reload()
        }
      })
    }
    catch (error) {
      console.log(error)
    }
  }

  const resetAuth = async () => {
    try {
      const userId = auth.currentUser.uid;
      await postWithCredentials(`${SERVER_URL}remove2FA`).then((response) => {
        console.log(response)
        if (response.data.success) {
          window.location.reload()
        }
      })
    }
    catch (error) {
      console.log(error)
    }
  }

  // for when the user is registering for Google 2FA
  const googleAuthContent = (
    <>
      <h3 className="subheading">Download Google Authenticator</h3>
      <p className="text">
        Download and open the Google Authenticator App on your mobile phone
      </p>
      <h3 className="subheading">Scan QR Code</h3>
      <div style={{ textAlign: "left" }}>
        <p className="text">
          Please scan the QR code show below to register this application. Then,
          enter the six digit code from your application below in order to
          complete setup.
        </p>
      </div>

      <div className="d-flex justify-content-center position-relative">
        <Card.Img
          className="mb-3 w-25 mt-3 p-0"
          src={data}
          alt=""
          style={{ boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)" }}
        />
        {gettingQRCode ? (
          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <Spinner animation="border" variant="primary" size="lg" />
          </div>
        ) : null}
      </div>
      {error && (
        <Alert variant="danger" className="d-flex align-items-center p-2">
          <span className="me-auto">{error}</span>
          <LoadingButton
            onClick={(e) => {
              renderQRCode();
            }}
            loading={gettingQRCode}
            className="me-0 mx-auto"
          >
            Refresh QR Code
          </LoadingButton>
        </Alert>
      )}
      {success && <Alert variant="success">{success}</Alert>}
      <div className="form-group">
        <span className="form-group-label">Verification Code</span>
        <Form.Control
          className="mb-3 form-group-input"
          type="text"
          onChange={(event) => setCode(event.target.value)}
          placeholder="Enter Code from Google Authenticator"
        ></Form.Control>
      </div>
      <LoadingButton
        style={{
          marginRight: "10px",
          width: "100%",
        }}
        loading={gettingQRCode}
        className="w-100 mb-1"
        onClick={verify2FA}
      >
        Verify Code
      </LoadingButton>
    </>
  );

  // for when the user is registering for CyAuth
  const cyAuthContent = (
    <>
      <p>Click the button below to register for CyAuth 2FA. You may need to create a CyAuth account.</p>
      <Row className="d-flex justify-content-center px-2">
        <Col>
          <Button 
            className="w-100"
            onClick={() => {
              navigate("/dashboard");
            }}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            className="w-100"
            onClick={async () => {
              await RegWithCyAuthPopup(cyAuthId);
              await setCyAuthVars();
            }}>
            Begin
          </Button>
        </Col>
      </Row>
    </>
  );

  // for when the user has not registered for 2FA
  const noAuthContent = (
    <>
      <p>
        { 
          "We offer 2-Factor Authentication (2FA) through the Google " + 
          "Authenticator app and through Cynorix Authentication (CyAuth). " 
        }
      </p>
      <Row>
        <Col>
          <Button 
            className="w-100"
            onClick={() => {
              setAuthType("google");
            }}>
              Google Authenticator
          </Button>
        </Col>
        <Col>
          <Button 
            className="w-100"
            onClick={() => {
              setAuthType("cyauth");
            }}>
              CyAuth
          </Button>
        </Col>
      </Row>
    </>
  );

  const resetAuthContent = (
    <div>
      <p>You have already registered for 2FA. Do you want to reset it?</p>
      <Row>
        <Col>
          <Button 
            className="w-100"
            onClick={() => {
              navigate("/dashboard");
            }}>
              No
          </Button>
        </Col>
        <Col>
          <Button 
            className="w-100"
            onClick={async () => {
              await resetAuth();
            }}>
              Yes
          </Button>
        </Col>
      </Row>
    </div>
  )

  function generateContent() {
    console.log("authType in generateContent:", authType);
    console.log("secret in generateContent:", has2FA);
    if (authType === "none") {
      return noAuthContent;
    } else if (has2FA) {
      return resetAuthContent;
    } else if (authType === "google") {
      return googleAuthContent;
    } else if (authType === "cyauth") {
      return cyAuthContent;
    }
  }

  const helpContent = (
    <>
      <p className="mb-1">
        <b>Warning</b>: Once you begin the 2FA process, your old 2FA is erased.
        Thus, if you decide to exit this process midway, you will invalidate
        your previous 2FA and must re-register the 2FA QR Code again.
      </p>
      <p>
        To minimize accidental clicks, this page requires you to confirm your
        intention before it begins the process of registering your for 2FA. To
        exit this page, you can click Go Back in the sidebar. To continue, click
        Yes on the page.
      </p>
      <hr />
      <p className="mb-1">
        The next page details a list of instructions for the user to register
        2FA. Once registered, you will then see a six-digit code in the
        authenticator app on your smartphone. Enter that six-digit code into the
        web application and click <b>Verify Code</b> to finish 2FA registration.{" "}
      </p>
      <p className="mb-1">
        <em>
          Note: This code is only valid for an interval of time, so it is
          important to enter the code before it expires. If the code you entered
          is incorrect, you can attempt the code again, or you can click the
          Refresh QR Code button,{" "}
          <b>
            after which you will need to restart the registration procedure
            again.
          </b>
        </em>
      </p>
    </>
  );

  return (
    <ComponentCard
      title="Set Up 2-Factor Authentication"
      helpContent={helpContent}
    >
      {generateContent()}
    </ComponentCard>
  );
}
