import toast from "react-hot-toast";
import axios from "axios";
import { auth } from "../firebase";
import { postWithCredentials } from "../contexts/AuthContext";

//================================================================================================
// Important data to fill out once we have a permanent account for Unilink-CyAuth
// The CyAuth organization for Unilink is:
//  email: temp@cynorix.com
//  password: Cynorix2023
//  APIKey: 
//  name: Unilink
//  organizationId: 
//================================================================================================

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// this is to register user
export const RegWithCyAuthPopup = async (cyAuthId) => {
    console.log(cyAuthId)
    // use userId and jwt to create session
    const sessionId = await createSession("eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwYTAzYmFmZTE2ZTBlZWUyN2JkMTY2NmZkMDQxNjExZTAzZTJhNmUxIiwiaWF0IjoxNjk0NzA2Nzk3NDk4LCJqdGkiOiJiODE3MWYzMjFjZGZmZWQ1OTA1OTFlOWNmODIzNWRkNmEwMmI3MWIxIiwiZXhwIjoxNjA5NDcwNjc5NzQ5OH0.VP7NbL533GbIBJIysKwXA8gOW7_Q5dr4QOlKE_mICK4eBKKmI9z1zyj2mDHg1auSDr1o1xYSwfwV-8tVLNYKBw", cyAuthId)
    // open popup to register user
    const test = await openRegPopup(sessionId);
    console.log("this is test", test)

    // new session for auth loop
    // const sessionIdAuth = await createSession("eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwYTAzYmFmZTE2ZTBlZWUyN2JkMTY2NmZkMDQxNjExZTAzZTJhNmUxIiwiaWF0IjoxNjk0NzA2Nzk3NDk4LCJqdGkiOiJiODE3MWYzMjFjZGZmZWQ1OTA1OTFlOWNmODIzNWRkNmEwMmI3MWIxIiwiZXhwIjoxNjA5NDcwNjc5NzQ5OH0.VP7NbL533GbIBJIysKwXA8gOW7_Q5dr4QOlKE_mICK4eBKKmI9z1zyj2mDHg1auSDr1o1xYSwfwV-8tVLNYKBw", cyAuthId)
    // const JWT = await openAuthPopup(sessionIdAuth);

    // const newSessionId = await createSession("eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwYTAzYmFmZTE2ZTBlZWUyN2JkMTY2NmZkMDQxNjExZTAzZTJhNmUxIiwiaWF0IjoxNjk0NzA2Nzk3NDk4LCJqdGkiOiJiODE3MWYzMjFjZGZmZWQ1OTA1OTFlOWNmODIzNWRkNmEwMmI3MWIxIiwiZXhwIjoxNjA5NDcwNjc5NzQ5OH0.VP7NbL533GbIBJIysKwXA8gOW7_Q5dr4QOlKE_mICK4eBKKmI9z1zyj2mDHg1auSDr1o1xYSwfwV-8tVLNYKBw", cyAuthId);
    // toast.success(`Validated: ${await validateJWT(JWT, newSessionId)}`);
}

export const AuthenticateWithAuthPopup = async (cyAuthId) => {
    const sessionIdAuth = await createSession("eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwYTAzYmFmZTE2ZTBlZWUyN2JkMTY2NmZkMDQxNjExZTAzZTJhNmUxIiwiaWF0IjoxNjk0NzA2Nzk3NDk4LCJqdGkiOiJiODE3MWYzMjFjZGZmZWQ1OTA1OTFlOWNmODIzNWRkNmEwMmI3MWIxIiwiZXhwIjoxNjA5NDcwNjc5NzQ5OH0.VP7NbL533GbIBJIysKwXA8gOW7_Q5dr4QOlKE_mICK4eBKKmI9z1zyj2mDHg1auSDr1o1xYSwfwV-8tVLNYKBw", cyAuthId)
    const JWT = await openAuthPopup(sessionIdAuth);

    const newSessionId = await createSession("eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwYTAzYmFmZTE2ZTBlZWUyN2JkMTY2NmZkMDQxNjExZTAzZTJhNmUxIiwiaWF0IjoxNjk0NzA2Nzk3NDk4LCJqdGkiOiJiODE3MWYzMjFjZGZmZWQ1OTA1OTFlOWNmODIzNWRkNmEwMmI3MWIxIiwiZXhwIjoxNjA5NDcwNjc5NzQ5OH0.VP7NbL533GbIBJIysKwXA8gOW7_Q5dr4QOlKE_mICK4eBKKmI9z1zyj2mDHg1auSDr1o1xYSwfwV-8tVLNYKBw", cyAuthId);
    toast.success(`Validated: ${await validateJWT(JWT, newSessionId)}`);
}

export const createSession = async (api_key, serviceUserId) => {
    try {
        const sessionApi = axios.create({
            baseURL: `${process.env.REACT_APP_SESSION_URL}/session`
        });
        const response = await sessionApi.post("/create-session", { serviceUserId }, {
            headers: { Authorization: `${api_key}` }
        });
        return response.data.sessionId;
    } catch (error) {
        throw new Error(error.response.data.error);
    }
};

export const openRegPopup = async (sessionId) => {
    window.open(`${process.env.REACT_APP_CYAUTH_POPUP}/authLogin/${encodeURIComponent(sessionId)}?url=${encodeURIComponent(window.location.origin)}&orgUid=${encodeURIComponent('UL4xJNJDXTg0RyiY5402cxAvMr63')}`, "Cynorix 2FA", "width=800,height=600");

    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
    const listen = () => new Promise((resolve) => {
        eventer(messageEvent, function (e) {
            console.log("origin: ", e.origin);
            if (e.origin !== process.env.REACT_APP_CYAUTH_POPUP) {
                return;
            }
            console.log("parent received message!: ", e.data);
            resolve();
        }, false);
    });
    await listen();
};

export const openAuthPopup = async (sessionId) => {
    window.open(`${process.env.REACT_APP_CYAUTH_POPUP}/authPortal/${encodeURIComponent(sessionId)}?url=${encodeURIComponent(window.location.origin)}&orgUid=${encodeURIComponent('UL4xJNJDXTg0RyiY5402cxAvMr63')}`, "Cynorix 2FA", "width=800,height=600");

    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
    const listen = () => new Promise((resolve) => {
        eventer(messageEvent, function (e) {
            console.log("origin: ", e.origin);
            if (e.origin !== process.env.REACT_APP_CYAUTH_POPUP) {
                return;
            }
            console.log("parent received message!: ", e.data);
            resolve(e.data.JWT);
        }, false);
    });
    return await listen();
};

export const validateJWT = async (authJWT, sessionId) => {
    try {
        const api = axios.create({
            baseURL: `${process.env.REACT_APP_CYAUTH_BACKEND_URL}/organization`
        });
        await api.post("/validateAuthJWT", {
            sessionId: sessionId,
            authJWT: authJWT
        });
        return true;
    } catch (e) {
        return false;
    }
};

export const checkCyAuth = async () => {
    try {
        const res = await postWithCredentials(SERVER_URL + "have2fa", {
            user_id: auth.currentUser.uid,
        });
        console.log("check", res.data);
        return res.data;
    } catch (error) {
        console.error(error);
        throw error; // You can rethrow the error to handle it further up the call stack if needed.
    }
} 
