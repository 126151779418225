import React, {useState, useEffect, useRef} from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import background from "../../assets/images/background-white.png";
import {useLocation, useNavigate} from "react-router-dom";
import FeedbackAlert from "./FeedbackAlert";
import LoadingButton from "../LoadingButton";
import ComponentCard from "./ComponentCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactPasswordChecklist from "react-password-checklist";
import {auth} from "../../firebase";

export default function ForgotPassword() {
    const navigate = useNavigate();
    const location = useLocation();

    const passwordRef = useRef();
    const passwordConfirmRef = useRef();

    const [email, setEmail] = useState("");
    const [feedback, setFeedback] = useState(null);
    const [password, setPassword] = useState("");
    const [passwordShownNew, setPasswordShownNew] = useState(false);
    const [passwordShownNewC, setPasswordShownNewC] = useState(false);
    const [oobCode, setOobCode] = useState(null);

    const togglePasswordNew = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShownNew(!passwordShownNew);
    };
    const togglePasswordNewC = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShownNewC(!passwordShownNewC);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const oobCode = searchParams.get("oobCode");
        setOobCode(oobCode);
    }, [location.search])


    const handleSendVerificationCode = async (e) => {
        e.preventDefault();
        try {
/*            // generate 6-digit code
            let randomCode = (Math.floor(Math.random() * 1000000) + 1000000)
                .toString()
                .substring(1);
            setCode(randomCode);*/
            auth.sendPasswordResetEmail(email)
                .then(link => {
                    // console.log(link)
                    setFeedback({
                        message: "Password recovery email sent. Please check your email for further instructions.",
                        variant: "success"
                    })
                })
                .catch(err => {
                    console.log(err.message)
                    if (err.message.includes("There is no user record corresponding to this identifier.")) {
                        setFeedback({
                            message: "The email you entered is not associated with any account in our database.",
                            variant: "danger"
                        })
                    }
                    else {
                        setFeedback({
                            message: "Oops, something went wrong...",
                            variant: "danger"
                        })
                    }
                })
        } catch (e) {
            setFeedback({
                message: e.message,
                variant: "danger",
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const promises = [];
        //Check password
        var regExp = /[a-zA-Z]/g;
        var hasNumber = /\d/;
        if (
            passwordRef.current.value.length < 10 ||
            !regExp.test(passwordRef.current.value) ||
            !hasNumber.test(passwordRef.current.value)
        ) {
            setFeedback({
                message: "New password too weak",
                variant: "danger",
            });
            return;
        }
        // check if the new passwords match
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            setFeedback({
                message: "Passwords do not match",
                variant: "danger",
            });
            return;
        }

        if (passwordRef.current.value && oobCode) {
            promises.push(auth.confirmPasswordReset(oobCode, passwordRef.current.value));
        }
        else if (!oobCode) {
            setFeedback("Your link is incorrect. Please open the link from your email again.");
        }

        Promise.all(promises)
            .then(() => {
                setFeedback({
                    loading: true,
                    message: "Password updated! Redirecting...",
                    variant: "success",
                });
                setTimeout(() => {
                    navigate("/login");
                }, 3000);
            })
            .catch((err) => {
                console.log(err)
                setFeedback({
                    message: "Failed to update password.",
                    variant: "danger",
                });
            })
    };

    const helpContent = (
        <p className="mb-1">
            If you forgot your password associated to your account, you can provide
            the email address you use to login with, and send an email containing a
            link to reset your password.
        </p>
    );

    return (
        <ComponentCard
            title={"Recover Password"}
            background={background}
            helpContent={helpContent}
        >
            {!oobCode ?
                <div
                    className="align-items-center"
                    style={{width: "100%", display: "contents"}}>
                    <Form

                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSendVerificationCode(e)
                        }}
                    >
                        <Form.Group className="mt-3">
                            <Row className="mb-2">
                                <Col
                                    xs={12}
                                    lg={2}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        padding: 0,
                                    }}
                                    className={`mb-2 mb-lg-0 
                        justify-content-center justify-content-lg-end`}
                                >
                                    Email
                                </Col>
                                <Col xs={12} lg={8}>
                                    <Form.Control
                                        type="email"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                        }}
                                    />
                                </Col>
                                <Col xs={12} lg={2}>
                                    <Button
                                        type={"submit"}
                                        className="w-100"
                                        variant="danger"
                                        style={{backgroundColor: "#800000", border: "none"}}
                                    >
                                        Send Link
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                    {/*{codeSent &&
                        <Form onSubmit={(e) => {
                            if (codeEntered === code) setEmailVerified(true);
                            else {
                                setFeedback({
                                    message: "Incorrect Code",
                                    variant: "danger"
                                });
                            }

                        }}>
                            <Form.Group className="mt-3">
                                <Row className="mb-2">
                                    <Col
                                        xs={12}
                                        lg={2}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: 0,
                                        }}
                                        className={`mb-2 mb-lg-0 
                        justify-content-center justify-content-lg-end`}
                                    >
                                        Confirmation Code
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            value={codeEntered}
                                            onChange={(e) => {
                                                setCodeEntered(e.target.value)
                                            }}
                                        />
                                    </Col>

                                </Row>
                            </Form.Group>
                            <LoadingButton
                                type="submit"
                                className="mt-4 w-100"
                            >
                                Submit
                            </LoadingButton>
                        </Form>
                    }
                    */}
                    {feedback && <FeedbackAlert feedback={feedback} className="mt-3 mb-0"/>}
                </div>

                :

                <Form onSubmit={handleSubmit} className="w-100 text-start">
                    <Form.Group id="password" className="mt-2">
                        <Row>
                            <Form.Label column className="text-center text-lg-end px-0">
                                New Password
                            </Form.Label>
                            <Col xs={12} lg={10}>
                                <div className="pass-wrapper">
                                    <Form.Control
                                        type={passwordShownNew ? "text" : "password"}
                                        className="form-control"
                                        ref={passwordRef}
                                        placeholder="**********"
                                        onChange={(event) => {
                                            setPassword(event.target.value);
                                        }}
                                        required
                                    />
                                    <div className="iconButton" onClick={togglePasswordNew}>
                                        <FontAwesomeIcon
                                            icon={passwordShownNew ? "eye-slash" : "eye"}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form.Group>
                    <div
                        className="form-group"
                        style={{
                            textAlign: "left",
                            justifyContent: "left",
                            marginTop: "10px",
                        }}
                    >
                        <span className="form-group-label"></span>

                        <ReactPasswordChecklist
                            rules={["minLength", "letter", "number"]}
                            minLength={10}
                            value={password}
                            className="form-group-input"
                        />
                    </div>
                    <Form.Group id="password-confirm" className="mt-2">
                        <Row>
                            <Form.Label column className="text-center text-lg-end px-0">
                                Confirm Password
                            </Form.Label>
                            <Col xs={12} lg={10}>
                                <div className="pass-wrapper">
                                    <Form.Control
                                        type={passwordShownNewC ? "text" : "password"}
                                        className="form-control"
                                        ref={passwordConfirmRef}
                                        placeholder="**********"
                                    />{" "}
                                    <div className="iconButton" onClick={togglePasswordNewC}>
                                        <FontAwesomeIcon
                                            icon={passwordShownNewC ? "eye-slash" : "eye"}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Row>
                        <Col>
                            <LoadingButton
                                loading={feedback && feedback.loading}
                                className="mt-3 w-100"
                                type="submit"
                            >
                                Update
                            </LoadingButton>
                        </Col>
                    </Row>
                    {feedback && <FeedbackAlert feedback={feedback} className="mt-3 mb-0"/>}
                </Form>
            }
        </ComponentCard>
    );
}
