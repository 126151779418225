import React, { useState } from "react";
import Signup from "./Signup";
import {
  Col,
  Container,
  Navbar,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Login from "./Login";
import ForgotPassword from "./new/ForgotPassword";
import OAuth from "./OAuth";
import SelectSponsor2 from "./SelectSponsor2";
import AcceptSponsorshipReq from "./AcceptSponsorshipReq";
import ServiceUsageInfo from "./new/ServiceUsageInfo";
import ChangePassword from "./ChangePassword";
import VerifyCoupon from "./VerifyCoupon";
import UpgradeService from "./UpgradeService";
import Dashboard from "./new/Dashboard";
import CancelService from "./CancelService";
import CreateClass2 from "./new/CreateClass2";
import MaintenanceDashboard from "./MaintenanceDashboard";
import EditServiceClass2 from "./new/EditServiceClass2";
import ShowServiceClass2 from "./new/ShowServiceClass";
import EditClass2 from "./new/EditClass2";
import DeleteAccount from "./DeleteAccount";
import DecryptionPageWithoutSignin from "./DecryptionPageWithoutSignin";
import CreateSubscription2 from "./new/CreateSubscription";
import Chunk from "./Chunk";
import Tryfreetrail from "./Tryfreetrail";
import Home from "./Home";
import Footer from "./new/Footer";
import PageNotFound from "./PageNotFound";
import CancelSponsorship from "./CancelSponsorship";
import RemoveAccount from "./RemoveAccount";
import "./new/CustomBootstrap.scss";

import DecryptionPage from "./DecryptionPage";
import ProfileDialog from "./ProfileDialog";

import "./App.css";
import "react-notifications-component/dist/theme.css";
import HomePageHelp from "./HomePageHelp";
import Faq2 from "./new/Faq2";
import Contact from "./Contact2";
import SignInModal from "./new/SignInModal";
import SideBar from "./new/SideBar";
import Encrypt from "./Encrypt";

export function getPageImage() {
  const IMG_GENERAL = "/img/CYN_Cynorix.jpg";
  const IMG_SIGNUP = "/img/CYN_Technologies.jpg";
  const IMG_SFS = "/img/CYN_sec_sharing_small.jpg";
  const IMG_2FA = "/img/CYN_Multifactor_small.jpg";
  const IMG_HELP = "/img/CYN_How_small.jpg";
  const IMG_SPONSORSHIP = "/img/CYN_Sponsor.jpg";

  switch (window.location.pathname) {
    case "/signup":
    case "/change-password":
    case "/remove-account":
    case "/verify-coupon":
      return IMG_SIGNUP;
    case "/encrypt":
    case "/decrypt":
    case "/dashboard":
      return IMG_SFS;
    case "/set-google-auth":
      return IMG_2FA;
    case "/faq":
      return IMG_HELP;
    case "/select-sponsor":
      return IMG_SPONSORSHIP;
    default:
      return IMG_GENERAL;
  }
}

const App = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  // -1 is loading, 0/1/2 = no pwd/set pwd/set security q
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();

  const maintenanceEmail =
    process.env.REACT_APP_MAINTENANCE_EMAIL || "maintenance@cynorix.com";

  const handleAuth = () => {
    navigate("/login");
  };
  const handleRegistration = () => {
    navigate("/signup");
  };

  const ProtectedRoute = ({ isAllowed, redirectPath, children }) => {
    if (isAllowed) {
      return <Outlet />;
    }

    return children;
  };

  const RequireSignInRoute = () => {
    return (
      <ProtectedRoute isAllowed={currentUser !== null}>
        <SignInModal
          handleAuth={handleAuth}
          handleRegistration={handleRegistration}
          isAuthorizing={false}
        />
      </ProtectedRoute>
    );
  };

  const isUserMaintenance =
    !!currentUser && currentUser.email === maintenanceEmail;

  const NotMaintenanceRoute = () => {
    return (
      <ProtectedRoute isAllowed={!isUserMaintenance}>
        <Navigate to={"/maintenance-dashboard"} />
      </ProtectedRoute>
    );
  };

  const MaintenanceRoute = () => {
    return (
      <ProtectedRoute isAllowed={isUserMaintenance}>
        <PageNotFound />
      </ProtectedRoute>
    );
  };

  const isUsageInfoPage = location.pathname === "/service-usage-info";
  const isHomePage = location.pathname === "/" || location.pathname === "/home";

  return (
    <div
      style={{
        minHeight: "100vh",
        position: "relative",
      }}
    >
      {/* Background Image */}
      <div
        style={{
          // TODO: create a url map to the different page urls
          backgroundImage: `url(${getPageImage()})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "fixed",
          height: "100vh",
          width: "100vw",
          filter: "blur(10px) opacity(15%)",
          display: isHomePage ? "none" : null,
        }}
      />
      {/* Navbar */}
      <Navbar
        bg="white"
        className="px-3"
        style={{ position: "sticky" }}
        expand="false"
      >
        <Navbar.Brand className="p-2">
          <img
            src="/img/CYN_logo.png"
            width={203}
            alt="cynorix logo"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          className={isUsageInfoPage ? null : "d-lg-none"}
          placement="end"
          onClick={() => {
            setShowDrawer(true);
          }}
          style={{
            boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
          }}
        />
      </Navbar>
      {/* App contents */}
      <Container
        fluid
        className="py-3 px-xs-0 px-md-5"
        style={{
          display:
            location.pathname === "/" || location.pathname === "/home"
              ? "none"
              : null,
        }}
      >
        <Row className="px-xs-0 px-md-5" style={{ flexWrap: "nowrap" }}>
          {/* Page Contents */}
          <Col className="px-2">
            <Routes>
              <Route element={<RequireSignInRoute />}>
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/verify-coupon" element={<VerifyCoupon />} />
                <Route path="/set-google-auth" element={<OAuth />} />
                <Route element={<NotMaintenanceRoute />}>
                  <Route path="/upgrade-service" element={<UpgradeService />} />
                  <Route exact path="/dashboard" element={<Dashboard />} />
                  <Route path="/chunk" element={<Chunk />} />
                  <Route
                    path="/create-subscription"
                    element={<CreateSubscription2 />}
                  />
                  <Route path="/select-sponsor" element={<SelectSponsor2 />} />
                  <Route
                    path="/accept-sponsorship-req"
                    element={<AcceptSponsorshipReq />}
                  />
                  <Route path="/cancel-service" element={<CancelService />} />
                  <Route
                    path="/cancel-sponsorship"
                    element={<CancelSponsorship />}
                  />
                  <Route
                    path="/service-usage-info"
                    element={<ServiceUsageInfo />}
                  />
                  <Route path="/free-trial" element={<Tryfreetrail />} />
                  <Route exact path="/encrypt" element={<Encrypt />} />
                  <Route
                    path="/show-service-class"
                    element={<ShowServiceClass2 />}
                  />
                </Route>
                <Route element={<MaintenanceRoute />}>
                  <Route
                    path="/maintenance-dashboard"
                    element={<MaintenanceDashboard />}
                  />
                  <Route
                    path="/edit-service-class"
                    element={<EditServiceClass2 />}
                  />
                  <Route path="/create-class" element={<CreateClass2 />} />
                  <Route path="/edit-class/:classId" element={<EditClass2 />} />
                </Route>
              </Route>
              <Route
                path="/delete-account/:userId"
                element={<DeleteAccount />}
              />
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/remove-account" element={<RemoveAccount />} />
              <Route exact path="/help" element={<HomePageHelp />} />
              <Route exact path="/faq" element={<Faq2 />} />
              <Route exact path="/contact" element={<Contact />} />

              {/* <Route exact path="/encrypt" render={this.checkLogin} /> */}

              <Route exact path="/payments" element={<ProfileDialog />} />
              <Route exact path="/decrypt" element={<DecryptionPage />} />
              <Route
                exact
                path="/decrypt2"
                element={<DecryptionPageWithoutSignin />}
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Col>
          {/* Sidebar */}
          {[
            "/home",
            "/",
            "/edit-service-class",
            "/edit-class",
            "/decrypt2",
          ].some((item) => {
            if (item === "/") {
              return location.pathname === item;
            } else {
              return location.pathname.includes(item);
            }
          }) ? null : (
            <Col
              className={"px-2 d-none d-lg-inline"}
              xs={12}
              style={{ flexBasis: 350, flexShrink: 1 }}
            >
              <Offcanvas
                show={showDrawer}
                onHide={() => {
                  setShowDrawer(false);
                }}
                responsive={isUsageInfoPage ? null : "lg"}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Navigation</Offcanvas.Title>
                </Offcanvas.Header>
                <SideBar />
              </Offcanvas>
            </Col>
          )}
        </Row>
      </Container>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
      </Routes>
      <div style={{ height: 50 }} />
      <Footer />
    </div>
  );
};

export default App;
