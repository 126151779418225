import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Alert, Modal, Table } from "react-bootstrap";
import { auth } from "../../firebase";
import Axios from "axios";
import { verifyCode } from "../../functions/verify";
import {
  getUserFromEmail,
  getUserFromSecondIndex,
} from "../../functions/getUser";
import { Card } from "react-bootstrap";
import { PLAN_STATE_PAID, PLAN_STATE_SPONSORED } from "./SideBar";
import LoadingButton from "./LoadingButton";
import ComponentCard from "./ComponentCard";
import { postWithCredentials } from "../../contexts/AuthContext"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const subscriptionColumns = [
  { dataField: "subscription_name", text: "Subscription" },
  { dataField: "basic_rate", text: "Rate" },
  { dataField: "currency", text: "Currency" },
  { dataField: "billing_period", text: "Billing Period" },
  { dataField: "encryption_limit", text: "Encryption Limit" },
  { dataField: "rate_past_limit", text: "Rate Past Limit" },
  { dataField: "has_email_service", text: "Email Service" },
  { dataField: "has_sms_service", text: "SMS Service" },
];

export default function ServiceUsageInfo() {
  const [usageData, setUsageData] = useState([]);
  const [subscriptionInfo, setSubscriptionInfo] = useState([]);
  const [userData, setUserData] = useState(null);
  const [productsInfo, setProductsInfo] = useState(null);
  const [sponsorEmail, setSponsorEmail] = useState("");
  const [isSponsoring, setIsSponsoring] = useState(false);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [removed, setRemoved] = useState("");
  const [row, setRow] = useState({});

  // Modal
  const codeRef = useRef();
  const [showRmSponsorshipModal, setShowRmSponsorshipModal] = useState(false);
  const handleClose = () => {
    setShowRmSponsorshipModal(false);
    setError("");
  };
  const handleRemoveSponsorship = (row) => {
    setRow(row);
    setShowRmSponsorshipModal(true);
    setError("");
  };

  // update user data
  useEffect(() => {
    setUserData(null);
    getUserFromEmail(auth.currentUser.email)
      .then((data) => {
        // console.log("THIS IS USER DATA", data);
        setUserData(data);
        if (data.SponsorIndex) {
          getUserFromSecondIndex(data.SponsorIndex).then((data) => {
            setSponsorEmail(data.email);
          });
        }
      })
      .catch((err) => {
        console.log("error occurred when getting user data", err);
      });
  }, []);

  // get products info
  useEffect(() => {
    getUserFromEmail("maintenance@cynorix.com")
      .then(({ products }) => {
        setProductsInfo(products);
      })
      .catch((err) => {
        console.log("error when getting products info", err);
        setProductsInfo(null);
      });
  }, []);

  useEffect(() => {
    async function aggregateSubscriptionInfo() {
      const costFormatter = new Intl.NumberFormat("en-CA", {
        style: "currency",
        currency: "CAD",
      });
      console.log(userData);
      try {
        // get product id
        let productId, nextProductId;
        let sponsorData;
        if (userData.FPP === PLAN_STATE_SPONSORED) {
          sponsorData = await getUserFromSecondIndex(userData.SponsorIndex);
          productId = sponsorData.service;
        } else {
          productId = userData.service;
          nextProductId = userData.service_updates;
        }

        // format subscription info for table and save in state.
        const info = [];
        [productId, nextProductId].forEach((id, index) => {
          if (id === undefined) return;
          const {
            basic_rate,
            billing_period,
            currency,
            subscription_name,
            attributes,
          } = productsInfo[id];
          const billingPeriodToStr = (period) =>
              period === "1" ? "monthly" : "annually";
          const booleanToYesNo = (bool) => (bool ? "Yes" : "No");
          info.push({
            currency,
            subscription_name,
            basic_rate: costFormatter.format(basic_rate),
            billing_period: billingPeriodToStr(billing_period),
            encryption_limit: attributes.files_encrypted.limit,
            rate_past_limit:
                productId ===
                "00e6ec406788f5846b2d8b38e2b4c23dc4271b71c16d548b827186d82f6d3bb4"
                    ? "N/A"
                    : costFormatter.format(attributes.files_encrypted.rate / 100),
            has_email_service: booleanToYesNo(attributes.emails_sent !== null),
            has_sms_service: booleanToYesNo(attributes.messages_sent !== null),
          });
        });
        setSubscriptionInfo(info);

        // GET CHUNK AND USAGE INFO *** VERY JANK ***
        // This application allows you a certain encryption limit, encLimit.
        // Once you hit the limit, you are allowed to purchase a single "chunk",
        // where it extends your encryption limit by that chunk size, encPerChunk.
        // There is no entry in the database to determine how many chunks you
        // have purchased, but it can be "guessed" by the canEncrypt flag and
        // some modular arithmetic.
        let chunksPurchased = 0;
        // Check if user has a sponsor. If so, take usage info from sponsor.

        let encUsed, canEncrypt;

        if (userData.FPP === 2) {
          ({ totalCount: encUsed, allowToUse: canEncrypt } =
              sponsorData.totalServiceCount.files_encrypted);
        } else {
          ({ totalCount: encUsed, allowToUse: canEncrypt } =
              userData.totalServiceCount.files_encrypted);
        }

        //5
        console.log(encUsed);
        //1
        console.log(canEncrypt);

        const productInfo = productsInfo[productId];
        let { limit: encLimit, chunk: encPerChunk } =
            productInfo.attributes.files_encrypted;
        [encLimit, encPerChunk] = [encLimit, encPerChunk].map((x) => parseInt(x));

        const encPastLimit = encUsed - encLimit;
        console.log("encPastLimit", encPastLimit);
        console.log("encPerChunk", encPerChunk);
        if (encPastLimit < 0) {
          chunksPurchased = 0;
        } else {
          chunksPurchased = Math.ceil(encPastLimit / encPerChunk);
          console.log("chunksPurchased", chunksPurchased);

          // if you saturated your chunks but you're still allowed to encrypt:
          // meaning we can guess that you are allowed one more chunk
          if (canEncrypt === 1 && encPastLimit >= 0 && encPastLimit % encPerChunk === 0) {
            chunksPurchased += 1;
          }
        }
        // how many encryptions do you have left?
        const encRemain = encLimit - encUsed + (chunksPurchased * encPerChunk);

        // FORMAT TABLE DATA
        // if the user can sponsor ppl
        // does the user sponsor people?
        const { listOfSponsorship } = userData;
        if (
            userData.FPP === PLAN_STATE_PAID &&
            listOfSponsorship &&
            Object.keys(listOfSponsorship).length > 0
        ) {
          // get total usage
          const totalServiceCount = userData.totalServiceCount;

          let sponsoredUsageList = [];
          await Promise.all(
              Object.keys(listOfSponsorship).map((property) =>
                  listOfSponsorship[property]
              )
          )
              .then((values) => {
                sponsoredUsageList = values.map((item) => ({
                  ...item,
                  sponsorData: true,
                }));
                setIsSponsoring(true);
              })
              .catch((err) => {
                console.log(
                    "error occured while getting sponsored users info",
                    err
                );
              });

          console.log(sponsoredUsageList);
          let totalEmployeeEncrypted = 0;
          if (sponsoredUsageList) {
            sponsoredUsageList.forEach((user) => {
              totalEmployeeEncrypted += user.files_encrypted;
            });
          }

          const totalRow = {
            first_name: "Total",
            last_name: undefined,
            files_encrypted: encUsed,
            files_decrypted: totalServiceCount.files_decrypted.totalCount,
            purchasedChunks: chunksPurchased,
            remainingEncryptions: encRemain,
            totalData: true,
          };

          let sponsorEncrypted = encUsed - totalEmployeeEncrypted;
          setUsageData([
            {
              ...userData,
              files_encrypted: sponsorEncrypted,
              purchasedChunks: chunksPurchased,
              remainingEncryptions: encRemain,
            },
            ...sponsoredUsageList,
            totalRow,
          ]);
          // if no sponsorships
        } else {
          const tableData = {
            ...userData,
            ...userData.serviceCount,
            purchasedChunks: chunksPurchased,
            remainingEncryptions: encRemain,
          };
          setUsageData([tableData]);
        }
      } catch (err) {
        console.log("Error occurred while getting subscription info", err);
      }
    }
    if (userData !== null && productsInfo !== null) {
      aggregateSubscriptionInfo();
    } else {
    }
  }, [userData, productsInfo]);

  const usageColumns = [
    { dataField: "first_name", text: "First Name" },
    { dataField: "last_name", text: "Last Name" },
    { dataField: "email", text: "Email" },
    { dataField: "files_encrypted", text: "Files Encrypted" },
    // { dataField: "files_decrypted", text: "Files Decrypted" },
    { dataField: "purchasedChunks", text: "Purchased Chunks" },
    { dataField: "remainingEncryptions", text: "Remaining Encryptions" },
    {
      dataField: "remove",
      text: isSponsoring ? "Remove Sponsorship" : "",
      formatter: (cell, row, { handleRemoveSponsorship, isRemoving }) => {
        if (row.sponsorData && !row.removed) {
          return (
            <LoadingButton
              size="sm"
              loading={isRemoving}
              onClick={() => {
                handleRemoveSponsorship(row);
              }}
            >
              Unsponsor
            </LoadingButton>
          );
        } else if (row.sponsorData && row.removed) {
          return (
            <>Unsponsored</>
          )
        }
      },
    },
  ];

  const handleRemove = async (userToRemove) => {
    try {
      const userEmail = auth.currentUser.email;
      const userData = await getUserFromEmail(userEmail);
      setLoading(true);
      ///////// verify code
      const verified = await verifyCode(
        codeRef.current.value,
        userData.user_id
      );
      // check if google authenticator code is correct
      if (!verified) {
        setError("Incorrect Code.");
        setLoading(false);
        return;
      }
      //Remove Sponsorship
      await removeSponsorship(
        userToRemove.user_id,
        userData.user_id,
        userToRemove.email,
        userEmail
      );
      // send email to notify employee that the request is accepted
      await sendRemoveEmail(userEmail, userToRemove);
    } catch (error) {
      alert("Error Removing Sponsorship");
    }
  };

  const removeSponsorship = async (
    employeeId,
    sponsorId,
    employeeEmail,
    sponsorEmail
  ) => {
    await postWithCredentials(SERVER_URL + "removeSponsorship", {
      employeeId: employeeId,
      sponsorId: sponsorId,
      employeeEmail: employeeEmail,
      sponsorEmail: sponsorEmail,
    })
      .then(() => {
        setRemoved("Sponsorship terminated.");
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
        throw new Error("Failed Remove Sponsorship");
      });
  };

  const sendRemoveEmail = async (sender, receiver) => {
    await postWithCredentials(SERVER_URL + "sendRemoveEmail", {
      sender: sender,
      receiver: receiver,
    })
      .then((response) => {
        // console.log('Email Sent.');
      })
      .catch((err) => {
        // console.log(err);
        throw new Error("Failed Sending Remove email");
      });
  };


  function generateTable(colData, rows, extraData = {}) {
    // console.log(rows);
    // console.log(extraData);
    if (rows === null || rows === undefined) {
      return null;
    }
    return (
      <Card
        className="p-3 pb-0"
        style={{
          boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Table responsive className="w-100">
          <thead style={{ color: "#424242" }}>
            <tr>
              {colData.map(({ text }) => (
                <th key={text}>{text}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                {colData.map((col) => {
                  if (col.formatter !== undefined) {
                    return (
                      <td key={col.dataField}>
                        {col.formatter(col, row, extraData)}
                      </td>
                    );
                  } else {
                    return <td key={col.dataField}>{row[col.dataField]}</td>;
                  }
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    );
  }

  const helpContent = (
    <p className="mb-1">
      This page details the subscription that you are currently on, your usage
      (as well as usage from anyone you sponsored), and your subscription for
      the next month. Your subscription for the next month will only show if you
      have changed your subscription.
    </p>
  );

  return (
    <ComponentCard
      title={"Service Usage Information"}
      helpContent={helpContent}
    >
      <h5>
        <b>My Subscription</b>
      </h5>
      {generateTable(subscriptionColumns, subscriptionInfo.slice(0, 1))}
      {/* Service Usage */}
      <h5 className="mt-3">
        <b>Usage</b>
      </h5>
      {sponsorEmail && (
        <div>
          You are currently being sponsored by <b>{sponsorEmail}</b>
        </div>
      )}
      {generateTable(usageColumns, usageData, {
        handleRemoveSponsorship,
        isRemoving: loading,
      })}
      {/* Another table if there is an upcoming subscription */}
      {subscriptionInfo.length === 2 ? (
        <>
          <hr />
          <h5>
            <b>Upcoming Subscription</b>
          </h5>
          {generateTable(subscriptionColumns, subscriptionInfo.slice(1))}
        </>
      ) : null}

      {/* MODALS */}
      <Modal show={showRmSponsorshipModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>2FA is required to remove selected user.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>
                Please enter your Google Authenticator code to unsponsor this
                user.
              </Form.Label>
              {error && <Alert variant="danger">{error}</Alert>}
              {usageData.length > 1 && removed && (
                <Alert variant="success">{removed}</Alert>
              )}
              <Form.Control type="text" ref={codeRef} required />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setError("");
              handleRemove(row);
            }}
            disabled={loading}
          >
            Remove Sponsorship
          </Button>
        </Modal.Footer>
      </Modal>
    </ComponentCard>
  );
}
