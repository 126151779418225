import Axios from 'axios';
import { postWithCredentials } from "../contexts/AuthContext"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const verifyCode = async (code, id) => {
	return await postWithCredentials(SERVER_URL + 'verifyQrCode', {
		code: code,
		userId: id,
	})
		.then((response) => {
			// console.log(response);
			return response.data.verified;
		})
		.catch((err) => {
			// console.log(err);
			// console.log(err.response.data);
			throw new Error('Error verifying');
		});
}

export { verifyCode };
