import React, { useState } from "react";
import { Button, Col, Row, Alert } from "react-bootstrap";
import { useAuth, postWithCredentials } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import ComponentCard from "./new/ComponentCard";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function RemoveAccount() {
  const { currentUser, generateEmailIndex } = useAuth();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const navigate = useNavigate();

  const sendDeleteAccountEmail = async (userEmail, userIndex) => {
    // console.log("this is email", userEmail);
    // console.log("this is userIndex", userIndex);
    return await postWithCredentials(SERVER_URL + "sendDeleteAccountEmail", {
      userEmail,
      userIndex,
    })
      .then(() => {
        setSuccess("Confirmation Email Sent");
        setTimeout(() => {
          setSuccess("");
          navigate("/");
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        throw new Error("Error sending confirmation email, please try again.");
      });
  };

  const handleDeleteAccount = async () => {
    try {
      setButtonDisable(true);
      const index = generateEmailIndex(currentUser.email);
      // console.log(index, "this is user index");
      // console.log(currentUser.uid);
      await sendDeleteAccountEmail(currentUser.email, currentUser.uid);
    } catch (e) {
      console.log(e);
      setError(e.message);
      setTimeout(() => {
        setError("");
      }, 2000);
    }
  };

  const helpContent = (
    <>
      <p className="mb-1">
        When you initially visit the page, it will ask you to confirm your
        intention in case you visited the page by accident. Upon clicking
        <b>Confirm</b>, it will send an email to your email inbox associated
        with your email address, which will contain a link to complete the
        process of deleting your account.
      </p>
      <p className="mb-0">
        Upon clicking the link, you will be taken to the webpage to delete your
        account. It requires you to provide your email address, your password,
        as well as a 2FA code from your phone if you set 2FA up. Once you click{" "}
        <b>Delete</b>, your account will be marked for deletion.
      </p>
    </>
  );

  return (
    <ComponentCard title="Delete Account" helpContent={helpContent}>
      <Row className="mb-3">
        <Col>
          <div className="text-start">
            To initiate the process of deleting your account, kindly click on
            the "<strong>confirm</strong>" button.
            <br></br>
            You will receive an <strong>email</strong> with instructions on how
            to complete the account deletion process. We appreciate your
            cooperation in this matter.
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-end">
          <Button
            className="w-100"
            disabled={buttonDisable}
            onClick={handleDeleteAccount}
          >
            Confirm
          </Button>
        </Col>
      </Row>
    </ComponentCard>
  );
}
