import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { getUserFromEmail } from "../functions/getUser";
import ComponentCard from "./new/ComponentCard";

export default function UpgradeService() {
  const [FPP, setFPP] = useState();
  const [hasSponsorships, setHasSponsorships] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await renderAccessStatus();
    })();
  }, []);

  const renderAccessStatus = async () => {
    try {
      const userEmail = auth.currentUser.email;
      const user = await getUserFromEmail(userEmail);
      // console.log(user);
      setHasSponsorships(Object.hasOwn(user, "listOfSponsorship"));
      setFPP(user.FPP);
    } catch (e) {
      alert("Error Rendering Page");
    }
    setLoading(false);
  };

  const navigate = useNavigate();

  const helpContent = (
    <>
      <p className="mb-1">
        For users that want to commit to a paid plan, they can click{" "}
        <b>Show Subscription Tiers</b>. There, they can compare and choose the
        best plan for them. Once selected, the user can click Purchase
        Subscription Tier at the bottom of the screen. The user will be asked to
        confirm and click Pay Now to complete their purchase. This will take
        them to the checkout page to complete their purchase. Each subscription
        comes with a different number of files that can be shared, and a rate
        for the next “chunk” of encryptions if they require more file
        encryptions.
      </p>
      <p className="mb-1">
        If the user wants to ask another person to cover the current user’s
        service fees, they can click <b>Request Sponsorship</b>. On this page,
        they are required to provide the sponsor’s email address, as well as a
        2FA code from their phone. This is set up in{" "}
        <b>Register 2-Factor Authentication</b>. If the user hasn’t registered
        for 2FA, they can click the link on the Request Sponsorship page, which
        will take them to the page to set up 2FA.
      </p>
    </>
  );

  return (
    <ComponentCard title="Upgrade Service" helpContent={helpContent}>
      {!loading && (
        <Row>
          <Col>
            <Button
              className="w-100 mt-3"
              onClick={() => {
                navigate("/select-sponsor");
              }}
              disabled={hasSponsorships || FPP === 2}
            >
              Request Sponsorship
            </Button>
          </Col>
          <Col>
            <Button
              className="w-100 mt-3"
              onClick={() => {
                navigate("/show-service-class");
              }}
            >
              Show Subscription Tiers
            </Button>
          </Col>
        </Row>
      )}
    </ComponentCard>
  );
}

