import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Row,
  Col,
  Alert
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import Axios from "axios";
import "./App.css";
import BannerImage from "./BannerImage";
import { postWithCredentials } from "../../contexts/AuthContext"
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function CreateClass(props) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [serviceClassName, setServiceClassName] = useState("");
  const [billingPeriod, setBillingPeriod] = useState("1");
  const [basicRate, setBasicRate] = useState(0);
  const [currency, setCurrency] = useState("CAD");
  const [hasAccess, setHasAccess] = useState(false);
  const [discountClass, setDiscountClass] = useState(false);

  const fileattributes = {
    name: "Files Encrypted",
    limit: "",
    chunk: 0,
    rate: "",
  };

  useEffect(() => {
    // check access before proceeding
    const userEmail = auth.currentUser.email;
    if (userEmail !== "maintenance@cynorix.com") return;
    setHasAccess(true);
  }, []);

  const navigate = useNavigate();


/*  const handleFormCheckOnChange = (event, exampleAttribute) => {
    mapOfAttributes.set(exampleAttribute, event.target.checked ? 1 : 0);
    setMapOfAttributes(mapOfAttributes);

    setListOfAttributes(
      Array.from(mapOfAttributes, ([key, value]) => ({
        key,
        value,
      }))
        .map(({ key, value }) => ({ [key]: value }))
        .filter((element) => {
          return Object.values(element)[0] === 1;
        })
        .map((attribute) => {
          return Object.keys(attribute)[0];
        })
    );
  };*/

/*  const handleSetAttributes = (event, attribute, prop) => {
    let tempAttributes = finalAttributes;
    let val = event.target.value;
    if (prop === "chunk") {
      val = 5;
    }
    const attribute_key = attribute.toLowerCase().split(" ").join("_");
    if (!finalAttributes[attribute_key]) {
      tempAttributes[attribute_key] = { name: attribute };
    }
    tempAttributes[attribute_key][prop] = val;
    console.log(tempAttributes);
    setFinalAttributes(tempAttributes);
  };*/

  const handleCreateClass = async (e) => {
    e.preventDefault();

    // validate chunk cost at least a dollar
    if (fileattributes.chunk * fileattributes.rate < 100) {
      alert("Chunk cost to low! increase chunk count or rate.");
      return;
    }

    const newClass = {
      subscription_name: serviceClassName,
      billing_period: billingPeriod,
      basic_rate: basicRate,
      currency,
      attributes: { files_encrypted: fileattributes },

    };
    console.log(newClass);

    try {
      setError("");
      let result = await storeClass(newClass);
      if (!result.success) {
        setError(result.error);
        return;
      }

      setSuccess("Class created successfully");
      setTimeout(() => {
        setSuccess("");
        navigate("/edit-service-class");
      }, 1000);
    } catch {
      setError("Failed to create class");
    }
  };

  const storeClass = async (newClass) => {
    const userId = auth.currentUser.uid;
    return await postWithCredentials(SERVER_URL + "createClass", {
      class: newClass,
      discountClass: discountClass,
      userId: userId,
    })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <>
      {hasAccess && (
        <>
          <Card
            style={{
              border: "none",
              boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Card.Body>
              <BannerImage url="/img/CYN_Cynorix.jpg" />
              <h4 className="mt-3">
                <b>Create a Class</b>
              </h4>
              <hr className="mt-0" />
              <Row>
                <Col>
                  <Form onSubmit={handleCreateClass}>
                    <Form.Group id="subscription_name" className="mb-3">
                      <Row>
                        <Col
                          xs={12}
                          lg={2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                          }}
                          className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                        >
                          Subscription Name
                        </Col>
                        
                        <Col xs={12} lg={10}>
                          <Form.Control
                            type="text"
                            required
                            onChange={(event) => {
                              event.preventDefault();
                              setServiceClassName(event.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group id="discounted_tiers" className="mb-3">
                      <Row>
                          <Col
                              xs={12}
                              lg={2}
                              style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: 0,
                              }}
                              className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                          >
                              Discounted Tiers
                          </Col>
                          <Col xs={12} lg={10}>
                              <Form.Check 
                                  type="radio"
                                  id="yes-option"
                                  name="discountedTiersOptions"
                                  label="Yes"
                                  inline
                                  onChange={(event) => {
                                      event.preventDefault();
                                      setDiscountClass(true)
                                    }}
                              />
                              <Form.Check 
                                  type="radio"
                                  id="no-option"
                                  name="discountedTiersOptions"
                                  label="No"
                                  defaultChecked
                                  inline
                                  onChange={(event) => {
                                      event.preventDefault();
                                      setDiscountClass(false)                                  }}
                              />
                          </Col>
                      </Row>
                  </Form.Group>


                    <Form.Group id="billing_period" className="mb-3">
                      <Row>
                        <Col
                          xs={12}
                          lg={2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                          }}
                          className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                        >
                          Billing Period
                        </Col>
                        
                        <Col xs={12} lg={10}>
                          <Form.Select
                            onChange={(event) => {
                              event.preventDefault();
                              setBillingPeriod(event.target.value);
                            }}
                          >
                            <option value={"1"}>1 Month</option>
                            <option value={"12"}>1 Year</option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group id="basic_rate" className="mb-3">
                      <Row>
                        <Col
                          xs={12}
                          lg={2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                          }}
                          className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                        >
                          Rate Per Month
                        </Col>
                        <Col xs={12} lg={5}>
                          <Form.Control
                            min={0}
                            type="number"
                            onChange={(event) => {
                              event.preventDefault();
                              setBasicRate(event.target.value);
                            }}
                            required
                          />
                        </Col>
                        <Col xs={12} lg={5}>
                          <Form.Select
                            onChange={(event) => {
                              event.preventDefault();
                              setCurrency(event.target.value);
                            }}
                          >
                            <option value={"CAD"}>CAD</option>
                            <option value={"USD"}>USD</option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group id="file-attribute">
                      <Row>
                        <Col
                          xs={12}
                          lg={2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                          }}
                          className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                        >
                          File attributes
                        </Col>
                        <Col xs={4}>
                          <Form.Control
                            min={0}
                            type="number"
                            required
                            placeholder="Encryption Limit"
                            onChange={(event) => {
                              event.preventDefault();
                              fileattributes.limit = event.target.value;
                            }}
                          />
                        </Col>
                        <Col xs={3}>
                          <Form.Control
                            min={0}
                            placeholder="# of Chunks"
                            type="number"
                            required
                            onChange={(event) => {
                              event.preventDefault();
                              fileattributes.chunk = parseInt(
                                event.target.value
                              );
                            }}
                          />
                        </Col>
                        <Col xs={3}>
                          <Form.Control
                            min={0}
                            type="number"
                            placeholder="Price per chunk"
                            required
                            onChange={(event) => {
                              event.preventDefault();
                              fileattributes.rate = event.target.value;
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      {/* <Form.Label column='lg'>
												Select from example attributes:
											</Form.Label>
                                            <Row>
											{exampleAttributes.map(
												(exampleAttribute, index) => {
													return (
                                                        <Col xs={6}>
														<Form.Check
															type='checkbox'
															id={index + 1}
															key={index + 1}
															label={
																exampleAttribute
															}
															disabled={confirmed}
															onChange={(
																event
															) => {
																//event.preventDefault();
																handleFormCheckOnChange(
																	event,
																	exampleAttribute
																);
															}}
														/>
                                                        </Col>
													);
												}
											)}
                                            </Row>

											<Button
												className='mt-3 mb-3'
                                                style={{
                                                    backgroundColor: "#800000",
                                                    color: "white",
                                                    border: "none",
                                                    marginRight: "10px",
                                                    width: "100%"
                                                  }}
												type='button'
												onClick={() => {
													setConfirmed(true);
												}}
												disabled={
													listOfAttributes.length ===
														0 || confirmed
												}
											>
												Confirm Attributes
											</Button>
											<hr />

											{listOfAttributes.length !== 0 && (
												<FormGroup>
													<Form.Label column='lg'>
														Set limit, chunk, and
														rate for selected
														attributes:
													</Form.Label>
													<Row>
														<Col xs={6}>
															<Form.Label column>
																Attributes
															</Form.Label>
														</Col>
														<Col xs={2}>
															<Form.Label column>
																Limit
															</Form.Label>
														</Col>
														<Col xs={2}>
															<Form.Label column>
																Chunk
															</Form.Label>
														</Col>
														<Col xs={2}>
															<Form.Label column>
																Rate (Cents)
															</Form.Label>
														</Col>
													</Row>
												</FormGroup>
											)}

											<ListGroup className='mb-3'>
												{listOfAttributes.map(
													(attribute, index) => {
														return (
															<ListGroup.Item
																key={index + 1}
																id={index + 1}
															>
																<Form.Group
																	id={
																		attribute
																	}
																>
																	<Row>
																		<Col
																			xs={
																				6
																			}
																		>
																			<Form.Label
																				column
																			>
																				{
																					attribute
																				}
																			</Form.Label>
																		</Col>
																		<Col
																			xs={
																				2
																			}
																		>
																			<Form.Control
																				disabled={
																					!confirmed
																				}
																				min={
																					0
																				}
																				type='number'
																				required
																				onChange={(
																					event
																				) => {
																					event.preventDefault();
																					handleSetAttributes(
																						event,
																						attribute,
																						'limit'
																					);
																				}}
																			/>
																		</Col>
																		<Col
																			xs={
																				2
																			}
																		>
																			<Form.Control
																				disabled={
																					!confirmed
																				}
																				min={
																					0
																				}
																				type='number'
																				required
																				onChange={(
																					event
																				) => {
																					event.preventDefault();
																					handleSetAttributes(
																						event,
																						attribute,
																						'chunk'
																					);
																				}}
																			/>
																		</Col>
																		<Col
																			xs={
																				2
																			}
																		>
																			<Form.Control
																				disabled={
																					!confirmed
																				}
																				min={
																					0
																				}
																				type='number'
																				required
																				onChange={(
																					event
																				) => {
																					event.preventDefault();
																					handleSetAttributes(
																						event,
																						attribute,
																						'rate'
																					);
																				}}
																			/>
																		</Col>
																	</Row>
																</Form.Group>
															</ListGroup.Item>
														);
													}
												)}
											</ListGroup> */}
                      <br />
                      {success && <Alert variant="success">{success}</Alert>}
                      {error && <Alert variant="danger">{error}</Alert>}

                      <Button
                        className="mt-3"
                        style={{
                          backgroundColor: "#800000",
                          color: "white",
                          border: "none",
                          marginRight: "10px",
                          width: "100%",
                        }}
                        type="submit"
                      >
                        Create Class
                      </Button>
                      <Link to="/edit-service-class" className="mt-3 w-100">
                        <Button
                          className="mt-3 w-100"
                          style={{
                            backgroundColor: "#800000",
                            color: "white",
                            border: "none",
                            marginRight: "10px",
                            width: "100%",
                          }}
                        >
                          Back
                        </Button>
                      </Link>
                    </Form.Group>
                  </Form>
                  {/* </Card.Body>
                    </Card> */}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </>
      )}
      {!hasAccess && <h4>Access denied.</h4>}
    </>
  );
}
